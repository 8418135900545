import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { trigger,  state,  style,  animate,  transition, query, stagger, keyframes } from '@angular/animations';
import $ from "jquery";
import { Router } from '@angular/router';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-review-doc',
  templateUrl: './review-doc.component.html',
  styleUrls: ['./review-doc.component.css'],
  	animations: [
	    trigger('page1Effect', [
	    	state('initial', style({
	        	transform: 'translateY(70px)',
	        	opacity: 0
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out'))
	    ]),
	    trigger('popupEffect', [
	    	state('initial', style({
	        	transform: 'translate(-50%,-75px)',
	        	opacity: 0
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out'))
	    ]),
	]
})
export class ReviewDocComponent implements OnInit {
	page1state = 'initial';
	popupstate = 'initial';
	@ViewChild('targetscroll') targetscroll: ElementRef;
  constructor(private router: Router,public app: AppComponent) { }

  ngOnInit(): void {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'auto' 
    });
  }

  	ngAfterViewInit() {
  		this.app.timerId.push(
        setTimeout(()=>{
    			this.page1state = 'final';
     		},2000)
      );
  	}
  	animationpage1Effect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
        this.app.timerId.push(
    			setTimeout(()=>{
    				this.targetscroll.nativeElement.scrollIntoView({ behavior: 'smooth' });
     			},1000)
        );
        this.app.timerId.push(
     			setTimeout(()=>{
    				$('.highlight').fadeIn();
    				$('.overlay').fadeIn();
    				this.popupstate = 'final';
     			},3000)
        );
  		}
  	}

    animationpopupdone($event){
      if($event.fromState == 'initial' && $event.toState == 'final'){
        this.app.showRefreshBtn = true;
      }
    }

}
