import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	manage:Boolean = false;
  	constructor(private router: Router) { }

  	ngOnInit(): void {
  		if(this.router.url == "/manage-sign"){
  		this.manage = true;
  		}
  	}

}
