import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { trigger,  state,  style,  animate,  transition, query, stagger, keyframes } from '@angular/animations';
import $ from "jquery";
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  animations: [
    trigger('correctEffect', [
      state('initial', style({
        transform: 'translate(-20%,-50%)',
        display:'none'
      })),
      transition('initial=>final',[
        style({
          display:'block'
        }), 
        animate('600ms ease-in-out')
      ])
    ]),
  ]
})

export class UploadComponent implements OnInit {
	showContent:Boolean = true;
  currentState = 'initial';
  fadeState = 'inital';
  public fontFace: any;
  isMobile:Boolean= false;
  isShown:Boolean =true;
  linktocopy;
  emailstring;

  constructor(public app: AppComponent) {}
	ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('restart_button');
    this.linktocopy = window.location.href; 
    this.isMobile = this.app.isMobile; 
    this.emailstring= "mailto:?body=Take the guided tour to see why DocuSign is trusted by hundreds of millions of users worldwide. Here is the URL: "+this.linktocopy+" &subject=DocuSign eSignature Tour";
    if( this.isMobile && this.isShown ){
        this.showContent = false;
    }
  }
  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('restart_button');
  }
  ngAfterViewInit() {
  }
  hide_mobilePopup(){
    this.isShown = false;
    this.showContent  = true;
  }
  showme(){
    $('.popup_box').fadeOut();
    $('.popup_overlay').fadeOut();
    $('.upload_box_inner').fadeIn();
    $('.container_box_inner h3').removeAttr('style');
    setTimeout(()=>{
      $('.dropDown').fadeIn();
      $('.upload_box_inner').css('border','1.5px solid #006FF9');
      $('.popup_overlay').fadeIn();
      this.currentState = "final";
      this.app.showRefreshBtn2 = true;
    },600)
  }
  animationfadeEffect($event){
    if($event.toState == "final"){
      $('.browse_btn').fadeIn();
      $('.dropDown').fadeIn();
      $('.upload_box_inner').css('border','1.5px solid #006FF9');
      $('.popup_overlay').fadeIn();
      this.currentState = "final";
    }
  }

}
