import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { trigger,  state,  style,  animate,  transition, query, stagger, keyframes } from '@angular/animations';
import $ from "jquery";
import { Router } from '@angular/router';
import {AppComponent} from '../app.component';

@Component({
  	selector: 'app-manage-sign',
  	templateUrl: './manage-sign.component.html',
  	styleUrls: ['./manage-sign.component.css'],
  	animations: [
	    trigger('step1Effect', [
	    	state('initial', style({
	        	transform: 'translate(-50%,70px)',
	        	opacity: 0
	      	})),
	      	state('hide', style({
	        	opacity: 0,
	        	display:'none'
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out'))
	    ]),
	    trigger('step2Effect', [
	    	state('initial', style({
	        	transform: 'translate(-50%,70px)',
	        	opacity: 0,
	        	display:'none'
	      	})),
	      	state('hide', style({
	        	opacity: 0,
	        	display:'none',
	        	transform: 'translate(-50%,70px)',
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('600ms ease-in-out')
		    ]),
		    transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('waitingEffect', [
	    	state('scale', style({
	        	transform : 'scale(1.2)'
	      	})),
	      	state('hide', style({
	        	opacity:0,
	        	display:'none'
	      	})),
	      	transition('initial=>scale',animate('600ms ease-in-out')),
	      	transition('scale=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('completeEffect', [
	    	state('initial', style({
	        	display:'none',
	        	opacity: 0,
	        	transform : 'scale(1.2)'
	      	})),
	      	state('final', style({
	        	transform : 'scale(1.2)'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
	      		animate('600ms ease-in-out')
	      	]),
	      	transition('final=>scale',animate('600ms ease-in-out'))
	    ]),
	    trigger('cursorEffect', [
	    	state('initial', style({
	        	transform: 'translate(-75px, 90px)',
	      	})),
	      	state('scale1', style({
	        	transform: 'scale(0.5)',
	        	
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out')),
	      	transition('final=>scale1',animate('600ms ease-in-out')),
	      	transition('scale1=>scale2',animate('600ms ease-in-out'))
	    ]),
	]
})
export class ManageSignComponent implements OnInit {
	step1state = 'initial';
	step2state = 'initial';
	waitingstate = 'initial';
	completestate = 'initial';
	cursorstate = 'initial';

  	constructor(private router: Router,public app: AppComponent) { }

	ngOnInit(): void {
	}

	ngAfterViewInit() {
  		this.app.timerId.push(
	  		setTimeout(()=>{
	  			$('.manage_list.manage_list1 .highlight').fadeIn();
	  			$('.overlay ').fadeIn();
	  			$('.manage_list.manage_list1 .box3 .status').css('font-weight','bold');
	   			this.step1state = 'final';
	   		},2000)
	   	);
  	}

  	animationstep1Effect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
	  		this.app.timerId.push(
		  		setTimeout(()=>{
		  			this.waitingstate = 'scale';
		   		},1600)
		   	);
	   		this.app.timerId.push(
		  		setTimeout(()=>{
		   			this.waitingstate = 'hide';
		   			this.completestate = 'final';
		   		},3200)
		   	);
	   		this.app.timerId.push(
		  		setTimeout(()=>{
		   			this.completestate = 'scale';
		   			$('.manage_list.manage_list1 .box1 .time').hide();
		   			$('.manage_list.manage_list1 .box1 .tick').fadeIn();
		   		},4800)
		   	);
	   	}
  	}


  	show_cursor(){
  		if(this.completestate == 'scale'){
  			$('.manage_list.manage_list1 .box3 .status').css('font-weight','normal');
	  		$('.manage_list.manage_list1 .highlight').fadeOut();
	  		$('.overlay ').fadeOut();
	  		$('.manage_list.manage_list1 .cursor').css('z-index','5').fadeIn();
	  		this.step1state = 'hide';
	  		this.app.timerId.push(
		  		setTimeout(()=>{
		  			$('.manage_list.manage_list1 .highlight').fadeIn();
		  			$('.overlay ').fadeIn();
		  			$('.manage_list.manage_list1').addClass('active');
		   			this.step2state = 'final';
		   			this.cursorstate = 'final';
		   		},1600)
		   	);
  		}
  	}

  	step2next(){
  		this.step2state = 'hide';
  		$('.manage_list.manage_list1 .highlight').fadeOut();
	  	$('.overlay ').fadeOut();
	  	this.app.showRefreshBtn = false;
  	}

  	animationstep2Effect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
	    	this.app.showRefreshBtn = true;
	    }
  		if($event.fromState == 'final' && $event.toState == 'hide'){
	  		this.app.timerId.push(
		  		setTimeout(()=>{
		  			this.cursorstate = 'scale1';
		   		},1000)
		   	);
	   		this.app.timerId.push(
		  		setTimeout(()=>{
		  			this.cursorstate = 'scale2';
		   		},2000)
		   	);
	   		this.app.timerId.push(
		  		setTimeout(()=>{
		  			this.router.navigate(['/review-doc']);
		   		},3000)
		   	);
	   	}
  	}

}
