<!-- <link *ngIf="isMobile" rel="stylesheet" [href]='sanitizer.bypassSecurityTrustResourceUrl(mobilecss)'>
<link *ngIf="isTablet || isMobile" rel="stylesheet" [href]='sanitizer.bypassSecurityTrustResourceUrl(tabletcss)'> -->
<link *ngIf="isMobile" rel="stylesheet" href="/finalversion9/assets/css/mobile-css.css">
<link *ngIf="isMobile || isTablet" rel="stylesheet" href="/finalversion9/assets/css/tablet-css.css">
<router-outlet></router-outlet>
<button *ngIf="showRefreshBtn" class="restart_button" (click)="restart_section($event)" i18n>
	Restart Section
</button>
<button *ngIf="showRefreshBtn2" class="restart_button restart_button2" (click)="restart_section($event)" i18n>
	Restart Section
</button>