import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UploadComponent } from './upload/upload.component';
import { HeaderComponent } from './header/header.component';
import { SelectRecipentComponent } from './select-recipent/select-recipent.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SignDocComponent } from './sign-doc/sign-doc.component';
import { ReviewsendComponent } from './reviewsend/reviewsend.component';
import { MobileRecieveComponent } from './mobile-recieve/mobile-recieve.component';
import { ManageSignComponent } from './manage-sign/manage-sign.component';
import { ReviewDocComponent } from './review-doc/review-doc.component';
import { EndPageComponent } from './end-page/end-page.component';

const routes: Routes = [
 {
    path: 'upload',
    component: UploadComponent,
    data: { title: 'Upload Document' }
  },
  {
    path: 'select-recipent',
    component: SelectRecipentComponent,
    data: { title: 'Select Recipent' },
    children: [                        
            {
                path:'begin',
                component: SelectRecipentComponent
            }
        ]
  },
  {
    path: 'select-recipent-begin',
    component: SelectRecipentComponent,
    data: { title: 'Select Recipent' }
  },
  {
    path: 'sign-doc',
    component: SignDocComponent,
    data: { title: 'Sign Document' }
  },
  {
    path: 'review-send',
    component: ReviewsendComponent,
    data: { title: 'Review & Send' }
  },
  {
    path: 'mobile-recieve',
    component: MobileRecieveComponent,
    data: { title: 'Mobile & Recive' }
  },
  {
    path: 'manage-sign',
    component: ManageSignComponent,
    data: { title: 'Mobile & Recive' }
  },
  {
    path: 'review-doc',
    component: ReviewDocComponent,
    data: { title: 'Review Document' }
  },
  {
    path: 'finish',
    component: EndPageComponent,
    data: { title: 'Start your Trial' }
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    data: { title: 'Online test' }
  },
  { path: '',
    component: UploadComponent,
    data: { title: 'DocuSign' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
