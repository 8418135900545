<link rel="stylesheet" href="select-recipent.component.css">

<app-header></app-header>
<div class="recipent_container_box">
	<div class="recipent_container_box_inner">
		<h3 class="title" i18n>Select recipients</h3>
		<div class="recipent_box1">
			<div class="left">
				<img src="assets/images/recipent/recipient_icon1.svg">
				<img src="assets/images/recipent/Line.svg">
			</div>
			<div class="right">
				<div class="right_text1" i18n>SENDER</div>
				<div class="right_text2" i18n>Larry Lee</div>
			</div>
		</div>
		<div class="recipent_box2">
			<div class="left">
				<img class="left_img1" src="assets/images/recipent/left_img1.svg">
				<img class="left_img2 left_box" src="assets/images/recipent/left_box.svg" style="display: none;">
				<img class="left_img2" src="assets/images/recipent/left_img2.svg" style="display: none;">
				<img class="left_img_no1" src="assets/images/recipent/no1.svg" style="display: none;">
				<img class="left_img_no2" src="assets/images/recipent/no2.svg" style="display: none;">
				<img class="left_img2 left_box" src="assets/images/recipent/left_box.svg" style="">
				<img class="left_img3" src="assets/images/recipent/left_img3.svg" style="transform-origin: top;" [@lineEffect]="lineState">
				<img class="left_img4" src="assets/images/recipent/left_img4.svg">
			</div>
			<div class="right">
				<div class="recipent_box2_text1"i18n >RECIPIENTS</div>
				<div class="highlight sign" style="display: none;"></div>
				<div class="recipent_box2_text2">
					<span class="check_box">
						<img class="checkbox" src="assets/images/recipent/checkbox.svg">
						<img class="checkbox_checked" style="display: none;" src="assets/images/recipent/checkbox_checked.svg"></span>
					<span class="set_text" i18n>Set signing order</span>
					<span class="view_text" i18n>View</span>
					<div class="view_popup" [@step4Effect]="step4state">
						<div class="top_arrow"></div>
						<div class="user_box_left_popup_inner">
							<span class="popup_titel_subHeading" i18n>Step 4</span>
							<span class="popup_titel" i18n>Drag and drop to set the signing order.</span>
							<button class="popup_btn" i18n (click)="(show_checked())">next</button>
						</div>
					</div>
				</div>
				<div class="user_box odd" [@swapEffect]="swapState">
					<div class="user_box_left">
						<div class="user_box_left_inner">
							<div class="form_field name">
								<input type="text" name="name" placeholder="Name">
								<img src="assets/images/recipent/Addressbook.svg">
							</div>
							<div class="form_field email">
								<input type="text" name="email" placeholder="Email">
							</div>
						</div>
						<div class="user_box_left_popup" [@step2Effect]="step2State">
							<div class="top_arrow"></div>
							<div class="user_box_left_popup_inner">
								<span class="popup_titel_subHeading" i18n>Step 2</span>
								<span class="popup_titel" i18n>Add recipients.</span>
								<button class="popup_btn" i18n (click)="show_prefilled()">next</button>
							</div>
						</div>
					</div>
						<div class="more"><span>More</span><img src="assets/images/recipent/select_icon.svg"></div>
						<div class="need_text">
							<div class="highlight" style="display: none;" [@highlightEffect]="highlightState"></div>
							<div class="need_text_inner">
								<img src="assets/images/recipent/Fill.svg"><span class="need_text1" i18n>NEEDS TO SIGN</span><img src="assets/images/recipent/select_icon.svg">
								<div class="user_box_left_popup" [@step3Effect]="step3State" (@step3Effect.done)="animationstep3Effect($event)">
									<div class="top_arrow"></div>
									<div class="user_box_left_popup_inner">
										<span class="popup_titel_subHeading" i18n>Step 3</span>
										<span class="popup_titel" i18n>Define recipient roles.</span>
										<span class="popup_dis" i18n>You’re in charge. Get what you need from each person.</span>
									</div>
								</div>
								<div class="drop_down" style="display: none;">
									<ul>
										<li><img src="assets/images/recipent/Fill.svg"><span i18n>Needs to Sign</span></li>
										<li><img src="assets/images/recipent/user-sign.svg"><span i18n>In Person Signer</span></li>
										<li><img src="assets/images/recipent/copied.svg"><span i18n>Receives a Copy</span></li>
									</ul>
								</div>
							</div>
						</div>
					<img class="drag_img" src="assets/images/recipent/drag.svg" style="display: none;">
				</div>
				<div class="user_box even" style="transform-origin: top;" [@slideEffect]="slideState" (@slideEffect.done)="animationslideEffect($event)">
					<div class="user_box_left">
						<div class="user_box_left_inner">
							<div class="form_field name">
								<input type="text" name="name" placeholder="Mary Wilson">
								<img src="assets/images/recipent/Addressbook.svg">
							</div>
							<div class="form_field email">
								<input type="text" name="email" placeholder="mary.wilson@mail.com">
							</div>
						</div>
					</div>
						<div class="more"><span i18n>More</span><img src="assets/images/recipent/select_icon.svg"></div>
						<div class="need_text"><img src="assets/images/recipent/Fill.svg"><span class="need_text1" i18n>NEEDS TO SIGN</span><img class="need_icon" src="assets/images/recipent/select_icon.svg"></div>
				</div>
				<div class="add_receipt">
					<div class="add_receipt_inner">
						<img class="recipient_img" src="assets/images/recipent/recipient-add.svg"><span i18n>ADD RECIPIENT </span><img class="recipient_dropdown" src="assets/images/recipent/menu.png">
					</div>
				</div>
				<div class="discription" i18n>Once an envelope has been routed to all recipients, and documents signed, each recipient will get a completed copy</div>
			</div>
		</div>
	</div>

	<div class="button_box">
		<a class="back">Back</a><a class="next">Next</a>
	</div>
	<div class="popup_box" [@popupEffect]="popupState" (@popupEffect.done)="animationpopupdone($event)">
		<h3>Your documents and recipients have been added.</h3>
		<p>Now, let’s prepare the document for signing. </p>
		<div class="show_me"><a routerLink="/sign-doc"><button>Next</button></a></div>
	</div>
</div>

<div class="overlay" style="display: none;"></div>
<app-footer></app-footer>

