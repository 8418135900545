import { Component, OnInit } from '@angular/core';
import { trigger,  state,  style,  animate,  transition, query, stagger, keyframes } from '@angular/animations';
import $ from "jquery";
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-mobile-recieve',
  templateUrl: './mobile-recieve.component.html',
  styleUrls: ['./mobile-recieve.component.css'],
  animations: [
	    trigger('mobile1Effect', [
	    	state('initial', style({
	        	transform: 'translateY(70px)',
	        	opacity: 0
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out'))
	    ]),
	    trigger('msgEffect', [
	    	state('initial', style({
	        	transform: 'translateY(70px)',
	        	opacity: 0
	      	})),
	      	transition('initial=>final',animate('400ms ease-in-out'))
	    ]),
	    trigger('step1Effect', [
	    	state('initial', style({
	        	transform: 'translateX(-70px)',
	        	opacity: 0
	      	})),
	      	state('final', style({
	        	opacity: 1
	      	})),
	      	state('hide', style({
	        	transform: 'translateX(-70px)',
	        	opacity: 0,
	        	transition: '400ms all linear',
	      	})),
	      	transition('initial=>final',animate('400ms ease-in-out'))
	    ]),
	    trigger('step2Effect', [
	    	state('initial', style({
	        	transform: 'translateX(-94px)',
	        	opacity: 0
	      	})),
	      	state('hide', style({
	        	transform: 'translateX(-70px)',
	        	opacity: 0
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out')),
	      	transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('mobile3Effect', [
	    	state('initial', style({
	        	opacity: 0,
	        	display: 'none'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('3000ms ease-in-out')
	      	])
	    ]),
	    trigger('tally1Effect', [
	    	state('initial', style({
	        	transform: 'translateX(-60px)',
	        	opacity: 0
	      	})),
	      	state('hide', style({
	        	opacity: 0
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out')),
	      	transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('tally2Effect', [
	    	state('initial', style({
	        	transform: 'translateX(-60px)',
	        	opacity: 0
	      	})),
	      	state('hide', style({
	        	transform: 'translateX(-70px)',
	        	opacity: 0
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out')),
	      	transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('tally3Effect', [
	    	state('initial', style({
	        	transform: 'translateX(-5.5%)',
	        	opacity: 0
	      	})),
	      	state('hide', style({
	        	opacity: 0
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out')),
	      	transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('tally4Effect', [
	    	state('initial', style({
	        	opacity: 0,
	        	display:'none'
	      	})),
	      	state('hide', style({
	        	transform: 'translateX(-70px)',
	        	opacity: 0
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
	      		animate('1500ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('500ms ease-in-out'))
	    ]),
	    trigger('circleEffect', [
	    	state('initial', style({
	    		transform: 'translate(104%, 110%)',
	        	opacity: 0,
	        	display: 'none'
	      	})),
	      	state('hide', style({
	        	transform: 'translateX(-55%)',
	        	opacity: 0
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
	      		animate('600ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('approveEffect', [
	    	state('scale', style({
	    		    transform: 'scale(1.5)',
	    		    top: '-5px',
    				position: 'relative',
	      	})),
	      	transition('initial=>scale',[
		      	style({
		      		position: 'relative',
		      	}),
		      	animate('1000ms ease-in-out')
		    ]),
	      	transition('scale=>final',animate('1000ms ease-in-out'))
	    ]),
	    trigger('fadeaproveEffect', [
	    	state('scalefade', style({
	    		    opacity: 0
	      	})),
	      	transition('initial=>scalefade',[
		      	style({
		      		transform: 'scale(1.2)'
		      	}),
		      	animate('1500ms ease-in-out')
		    ])
	    ]),
	    trigger('tally5Effect', [
	    	state('initial', style({
	    		transform: 'translateX(-70px)',
	        	opacity: 0,
	        	display:'none'
	      	})),
	      	state('hide', style({
	        	opacity: 0
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
	      		animate('600ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('tally6Effect', [
	    	state('initial', style({
	    		transform: 'translateX(-70px)',
	        	opacity: 0,
	        	display:'none'
	      	})),
	      	state('hide', style({
	        	opacity: 0
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
	      		animate('600ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('tally7Effect', [
	    	state('initial', style({
	    		transform: 'translateY(-70px)',
	        	opacity: 0,
	        	display:'none'
	      	})),
	      	state('hide', style({
	        	opacity: 0,
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
	      		animate('600ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('popupEffect', [
	    	state('initial', style({
	    		transform: 'translate(-50%, -70%)',
	        	opacity: 0,
	        	display:'none'
	      	})),
	      	state('hide', style({
	        	opacity: 0
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
	      		animate('600ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('600ms ease-in-out'))
	    ]),
	    trigger('scaleSignEffect', [
	    	state('initial', style({
	    		transform: 'scale(.8)',
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out')),
	        transition('final=>shake', animate('400ms', keyframes([
	      	  style({ transform: 'translateX(0)' }),
	          style({ transform: 'translateX(-10px)' }),
	          style({ transform: 'translateX(0)' }),
	          style({ transform: 'translateX(10px)' }),
	          style({ transform: 'translateX(0)' }),
	        ])))
	    ]),
	    trigger('buttonShakeEffect', [
	      	transition('initial=>shake', animate('400ms', keyframes([
	      	  style({ transform: 'translateX(0)' }),
	          style({ transform: 'translateX(-10px)' }),
	          style({ transform: 'translateX(0)' }),
	          style({ transform: 'translateX(10px)' }),
	          style({ transform: 'translateX(0)' }),
	        ])))
	    ]),
	]
})
export class MobileRecieveComponent implements OnInit {
	mobile1State = 'initial';
	msgState = 'initial';
	step1State = 'initial';
	step2State = 'initial';
	mobile3State = 'initial';
	tally1State = 'initial';
	tally2State = 'initial';
	tally3State = 'initial';
	tally4State = 'initial';
	circleState = 'initial';
	approveState = 'initial';
	tally5State = 'initial';
	tally6State = 'initial';
	tally7State = 'initial';
	popupState = 'initial';
	fadeaproveState = 'initial';
	scaleSignState = 'initial';
	shakeState = 'initial';

  	constructor(public app: AppComponent) { }

  	ngOnInit(): void {
  	}

  	ngAfterViewInit() {
  		this.app.timerId.push(
	  		setTimeout(()=>{
	   			this.mobile1State = 'final';
	   		},2000)
	   	);
  	}

  	animationmobile1done($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
	  		this.app.timerId.push(
		  		setTimeout(()=>{
		   			this.msgState = 'final';
					this.step1State = 'final';
		   		},1000)
		   	);
	   	}
  	}

  	animationstep1done($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
	  		this.app.timerId.push(
		  		setTimeout(()=>{
					this.step1State = 'hide';
					$('.image_box1').fadeOut('600');
					$('.image_box2').fadeIn('3000');
		   		},4500)
		   	);
	   	}
	   	if($event.fromState == 'final' && $event.toState == 'hide'){
	   		this.app.timerId.push(
		  		setTimeout(()=>{
					this.step2State = 'final';
		   		},1600)
		   	);
	   	}
  	}

  	show_documnet(){
		$('.image_box2').fadeOut();
		$('.image_box3').fadeIn();
		this.mobile3State = 'final';
		this.tally1State = 'final';
		this.step2State = 'hide';
  	}

  	animationtally1done($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			this.app.timerId.push(
	  			setTimeout(()=>{
		  			$('.overlay').fadeIn();
		  			$('.top_header_right').css('z-index',3);
		  			$('.top_header_right').css('border-radius', '14px');
		  			this.tally1State = 'hide';
		  			this.tally2State = 'final';
	  			},2000)
	  		);
  		}
  	}

  	scroll_document(el: HTMLElement){
  		if(this.tally2State == 'final'){
  			$('.overlay').fadeOut();
	  		$('.top_header_right').css('z-index',2);
	  		$('.top_header_right').fadeOut('600');
	  		$('.top_header_right1').fadeIn('600');
	  		el.scrollIntoView({ behavior: 'smooth' });
	  		$('.row').addClass('onscroll');
	  		$('.image_box3 .image_box').addClass("scrollpage");
	  		this.app.timerId.push(
		  		setTimeout(()=>{
		  			$('.overlay').fadeIn('600');
		  			$('.blue_highlighted').fadeIn('600');
		  			this.tally2State = 'hide';
		  			this.scaleSignState = 'final';
		  			this.tally3State = 'final';
				},2500)
			);
  		}
  	}

  	show_sign(){
  		$('.overlay').fadeOut();
  		$('.top_header').fadeOut();
  		$('.text_container_box1').fadeOut();
  		$('.page2 .highlight').fadeOut();
  		$('.text_container_box2').fadeIn('3000');
  		this.tally3State = 'hide';
  		this.tally4State  = 'final';
  		$('.field2 .signicon').hide();
  		$('.field2 .signimg').show();
  		$('.top_header_right1 .text_sign').hide();
  		$('.top_header_right1 .text_payment').show();
  	}

  	show_after_Sign(el: HTMLElement){
  		$('.text_container_box2').fadeOut();
  		$('.top_header').fadeIn('3000');
  		$('.text_container_box1').fadeIn('3000');
  		this.app.timerId.push(
	  		setTimeout(()=>{
	  			el.scrollIntoView({ behavior: 'smooth' });
	  			this.tally4State = 'hide';
			},4000)
		);
  	}

  	animationtally4done($event){
  		if($event.fromState == 'final' && $event.toState == 'hide'){
	  		this.app.timerId.push(
		  		setTimeout(()=>{
		  			$('.top_header_right2').css('z-index',3);
		  			$('.overlay').fadeIn('600');
		  			$('.top_header_right2').fadeIn('600');
				},1000)
			);
			this.app.timerId.push(
				setTimeout(()=>{
		  			this.circleState = 'final';
				},2600)
			);
		}
  	}

  	animationcircledone($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			this.app.timerId.push(
		  		setTimeout(()=>{
		  			$('.overlay').fadeOut('600');
		  			$('.top_header_right2').fadeOut('600');
				},1600)
			);
			this.app.timerId.push(
				setTimeout(()=>{
	  				this.approveState = 'scale';
	  			},2200)
	  		);
		}
  	}

  	animationapprovedone($event){
  		if($event.fromState == 'initial' && $event.toState == 'scale' ){
  			this.app.timerId.push(
		  		setTimeout(()=>{
		  			this.tally5State = 'final';
				},1000)
			);
		}
  	}

  	animationtally5done($event){
		if($event.fromState == 'final' && $event.toState == 'hide'){
			this.app.timerId.push(
		  		setTimeout(()=>{
		  			this.tally6State = 'final';
		  			$('.top_header').fadeOut();
		  			$('.text_container_box1').fadeOut();
	  				$('.text_container_box3').fadeIn('3000');
				},1500)
			);
		}
  	}

  	show_approved(){
  		$('.approve_decline .unchecked').fadeOut('3000');
  		$('.approve_decline ul li:first span.action_btn').css('background','#449A5E').fadeIn('3000');
  		$('.approve_decline ul li:first span.action_btn').css('color','#FFFFFF').fadeIn('3000');
  		this.approveState = 'final';
  		this.tally5State = 'hide';
  		this.fadeaproveState = 'scalefade';
  	}

  	animationtally6done($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			this.app.timerId.push(
	  			setTimeout(()=>{
	  				$('.payment_method_form .debitcard').attr('placeholder','5375 4322 8766 1102').fadeIn('600');
	  				$('.payment_method_form .month').attr('placeholder','05/22').fadeIn('600');
	  				$('.payment_method_form .cvv').attr('placeholder','...').fadeIn('600');
	  				$('.payment_method_form').addClass('input_black');
				},4000)
			);
			this.app.timerId.push(
				setTimeout(()=>{
  					this.tally6State = 'hide';
	  				$('.text_container_box3').fadeOut();
  					$('.text_container_box4').fadeIn('3000');		
	  			},8000)
	  		);
		}
		if($event.fromState == 'final' && $event.toState == 'hide'){
			this.app.timerId.push(
				setTimeout(()=>{
	  				$('.overlay').fadeIn();
	  				$('.finish_button  .highlight ').fadeIn();
	  				this.tally7State = 'final';
		  		},3500)
		  	);
		}
  	}

  	animationtally7done($event){
  		if($event.fromState == 'final' && $event.toState == 'hide'){
  			this.app.timerId.push(
		  		setTimeout(()=>{
		  			$('.finish_button button').css('z-index',2);
		  			$('.fullpage_overlay').fadeIn('600');
		  			this.popupState = 'final';
				},1000)
			);
		}
  	}

  	animationpopupdone($event){
      if($event.fromState == 'initial' && $event.toState == 'final'){
        this.app.showRefreshBtn = true;
      }
    }

  	send_finish(){
	  	if(this.tally7State == 'final'){
	  		$('.overlay').fadeOut('600');
	  		$('.finish_button  .highlight ').fadeOut();
		  	this.tally7State = 'hide';
	  	}
  	}
  	add_Shake(){
  		if(this.scaleSignState == 'final' && this.tally3State == 'final'){
  			this.scaleSignState = 'shake';
  		}
  		if(this.tally7State == 'final'){
  			this.shakeState = 'shake';
  		}
  	}
  	animationbuttonShakedone($event){
  		if($event.fromState == 'initial' && $event.toState == 'shake'){
  			this.shakeState = 'initial';
  		}
  	}
  	animationscaleSignEffect($event){
  		if($event.fromState == 'final' && $event.toState == 'shake'){
  			this.scaleSignState = 'final';
  		}
  	}
}
