import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UploadComponent } from './upload/upload.component';
import { SelectRecipentComponent } from './select-recipent/select-recipent.component';
import { SignDocComponent } from './sign-doc/sign-doc.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ReviewsendComponent } from './reviewsend/reviewsend.component';
import { MobileRecieveComponent } from './mobile-recieve/mobile-recieve.component';
import { ManageSignComponent } from './manage-sign/manage-sign.component';
import { ReviewDocComponent } from './review-doc/review-doc.component';
import { EndPageComponent } from './end-page/end-page.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    UploadComponent,
    SelectRecipentComponent,
    SignDocComponent,
    HeaderComponent,
    FooterComponent,
    ReviewsendComponent,
    MobileRecieveComponent,
    ManageSignComponent,
    ReviewDocComponent,
    EndPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DeviceDetectorModule,
    ClipboardModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
