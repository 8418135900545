import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { trigger,  state,  style,  animate,  transition, query, stagger, keyframes } from '@angular/animations';
import $ from "jquery";
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-reviewsend',
  templateUrl: './reviewsend.component.html',
  styleUrls: ['./reviewsend.component.css'],
  animations: [
	    trigger('step1Effect', [
	    	state('initial', style({
	        	transform: 'translateX(30%)',
	        	opacity: '0'
	      	})),
	      	state('hide', style({
	      		transform: 'translateX(30%)',
	        	opacity: '0'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('600ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('400ms ease-in-out'))
	    ]),
	    trigger('step2Effect', [
	    	state('initial', style({
	        	transform: 'translateX(40%)',
	        	opacity: '0'
	      	})),
	      	state('hide', style({
	        	opacity: '0'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('600ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('400ms ease-in-out'))
	    ]),
	    trigger('step3Effect', [
	    	state('initial', style({
	        	transform: 'translateX(15%)',
	        	opacity: '0'
	      	})),
	      	state('hide', style({
	      		transform: 'translateX(15%)',
	        	opacity: '0'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('600ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('400ms ease-in-out'))
	    ]),
	    trigger('popupEffect', [
	    	state('initial', style({
	        	transform: 'translate(-50%, -17%)',
	        	opacity: '0',
	        	display:'none'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('600ms ease-in-out')
		    ])
	    ]),
	    trigger('shakeEffect', [
      		transition('initial=>shake', animate('400ms', keyframes([
	      	  style({ transform: 'translateX(0)' }),
	          style({ transform: 'translateX(-10px)' }),
	          style({ transform: 'translateX(0)' }),
	          style({ transform: 'translateX(10px)' }),
	          style({ transform: 'translateX(0)' }),
	        ])))
    	]),
	]
})
export class ReviewsendComponent implements OnInit {
	
	step1State = 'initial';
	step2State = 'initial';
	step3State = 'initial';
	popupState = 'initial';
	shakestate = 'initial';
	
  	constructor(public app: AppComponent) { }

  	ngOnInit(): void {
  	}

  	ngAfterViewInit() {
  		this.app.timerId.push(
	  		setTimeout(()=>{
	  			$('.overlay').css('background','rgba(0, 0, 0, .4)');
	  			$('.form_section .highlight').fadeIn();
	   			$('.overlay').fadeIn();
	   			$('.form_box ').css('z-index',5);
	      		this.step1State = 'final';
	   		},2000)
	   	);
  	}

  	animationstep1done($event){
  		if($event.fromState == 'initial' && $event.toState == 'final' ){
		  	this.app.timerId.push(	
		  		setTimeout(()=>{
		  			$('.overlay').fadeOut();
		  			this.step1State = 'hide';
		  			$('.form_box ').css('z-index',0);
	   				$('.form_section .highlight').fadeOut();
	   				$('.form_box ').addClass('input_black');
	   				$('.form_section input').prop('placeholder','Sign the Tally Master Services Agreement.');
	   				$('.form_section textarea').prop('placeholder','Please sign MSA by Friday.\n \nLarry Lee');
		  		},3500)
		  	);
		  	this.app.timerId.push(
		  		setTimeout(()=>{
		  			$('.overlay').fadeIn();
		  			$('.send_container').removeAttr('style');
		  			this.step2State = 'final';
		  			$('.every_day').fadeIn();
	   				$('.send_container .highlight').fadeIn();
		  		},4500)
		  	);
	  	}
  	}

  	animationstep2done($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			$('img.checked').fadeIn();
	  	}
  	}


  	show_next(){
  		$('.overlay').fadeOut();
  		$('.send_container').css('z-index',0);
  		$('.send_container .highlight').fadeOut();
  		this.step2State = 'hide';
  		this.app.timerId.push(
	  		setTimeout(()=>{
	  			$('.overlay').fadeIn();
	  			this.step3State = 'final';
	  			$('.next_btn').removeAttr('style');
				$('.button_section .highlight').fadeIn();
	  		},1000)
	  	);
  	}

  	add_Shake(){
  		if(this.step3State == 'final'){
  			this.shakestate = 'shake';
  		}
  	}
  	animationshakeEffect($event){
  		if($event.fromState == 'initial' && $event.toState == 'shake'){
  		console.log('inside animationshakeEffect');
  			this.shakestate = 'initial';
  		}
  	}
  	Show_popup(){
  		$('.overlay').fadeOut();
  		this.step3State = 'hide';
  		$('.next_btn').css('z-index',0);
		$('.button_section .highlight').fadeOut();
		this.app.timerId.push(
	  		setTimeout(()=>{
	  			$('.overlay').removeAttr('style');
	  			$('.overlay').fadeIn();
	  			this.popupState = 'final';
	  		},1000)
	  	);
  	}
  	animationpopupdone($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			this.app.showRefreshBtn = true;
  		}
  	}
}
