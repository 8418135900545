<app-header></app-header>
<div class="top_header">
	<div class="top_header_overlay" (click)="add_Shake()" style="display: none;"></div>
	<div class="top_header_inner">
		<div class="left_side">
			<div class="drop_box" >
				<div class="highlight" style="display: none;" [@highlightEffect]="highlightState"></div>
				<div class="top_name">
					<span class="circle yellow_circle"></span><span class="text" i18n>Mary Wilson</span><span class="image"><img src="assets/images/sign-doc/select_down_arrow.svg"></span>
				</div>
				<ul style="transform-origin: top;" [@dropEffect]="dropstate" (@dropEffect.done)="animationdropEffect($event)">
					<li><span class="tick" [@tickEffect]="tickstate" (@tickEffect.done)="animationtickEffect($event)"><img src="assets/images/sign-doc/tick.svg"></span><span class="circle yellow_circle"></span><span class="text" i18n>Mary Wilson</span></li>
					<li><span class="tick blank"></span><span class="circle cyan"></span><span class="text" i18n>Blake Hayes</span></li>
				</ul>
				<div class="user_box_left_popup_inner popup" [@step2Effect]="step2state" (@step2Effect.done)="animationstep2Effect($event)">
					<span class="popup_titel_subHeading" i18n>Step 2</span>
					<span class="popup_titel" i18n>Assign fields to each recipient.</span>
					<span class="popup_dis" i18n>Make sure you get exactly what you need from each person.</span>
					<div class="top_arrow"></div>
				</div>
			</div>
		</div>
		<div class="right_side">
			<span class="right_side_image"><img src="assets/images/sign-doc/header_toolbar.svg"></span>
		</div>
	</div>
</div>	

<div class="container_box">
	<div class="left">
		<div class="left_inner">
			<div class="tab">
				<ul>
					<li><img src="assets/images/sign-doc/Standard_Selected.svg" class="Standard_Selected">
						<img src="assets/images/sign-doc/Standard_Selected_blue.svg" class="Standard_Selected_blue" style="display: none;"></li>
					<li><img src="assets/images/sign-doc/setting.svg"></li>
					<li><img src="assets/images/sign-doc/Merge_Field.svg"></li>
					<li><img src="assets/images/sign-doc/search.svg"></li>
				</ul>
			</div>
			<div class="tab_menu">
				<div class="user_box_left_popup_inner popup" [@step1Effect]="step1state" (@step1Effect.done)="animationstep1Effect($event)">
					<span class="popup_titel_subHeading" i18n>Step 1</span>
					<span class="popup_titel" i18n>Simply drag and drop.</span>
					<span class="popup_dis"><span class="blue" i18n>Placing signature and required action fields onto your documents is easy.
					</span></span>
					<div class="top_arrow"></div>
				</div>
				<div class="highlight" style="display: none;"></div>
				<div class="highlight_black" (click)="add_Shake()" style="display: none;"></div>
				<div class="tab_menu_inner yellow">
					<h5 i18n>Standard Fields</h5>
					<ul class="side_menu menu1">
						<li class="signature"><span class="yellow"><img src="assets/images/sign-doc/Signature.svg"></span><span i18n>Signature</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/Initial.svg"></span><span i18n>Initial</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/Date_Signed.svg"></span><span i18n>Date Signed</span></li>
					</ul>
					<ul class="side_menu menu2">
						<li><span class="yellow"><img src="assets/images/sign-doc/Name.svg"></span><span i18n>Name</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/Email.svg"></span><span i18n>Email</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/Company.svg"></span><span i18n>Company</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/Title.svg"></span>Title</li>
					</ul>
					<ul class="side_menu menu2">
						<li><span class="yellow"><img src="assets/images/sign-doc/Text.svg"></span><span i18n>Text</span></li>
						<li class="checkbox"><span class="yellow"><img src="assets/images/sign-doc/Checkbox.svg"></span><span i18n>Checkbox</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/Drop_Down.svg"></span><span i18n>Drop Down</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/Radio_Button.svg"></span><span i18n>Radio Button</span></li>
						<li class="paymentItems"><span class="yellow"><img src="assets/images/sign-doc/payment.svg"></span><span i18n>Payment Item</span></li>
					</ul>
					<ul class="side_menu menu2">
						<li><span class="yellow"><img src="assets/images/sign-doc/attachment.svg"></span><span i18n>Attachment</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/Note.svg"></span><span i18n>Note</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/approve.svg"></span><span i18n>Approve/Decline</span></li>
						<li><span class="yellow"><img src="assets/images/sign-doc/Formula.svg"></span><span i18n>Formula</span></li>
					</ul>
				</div>
			</div>
		</div>
		
	</div>
	<div class="middle" >
		<div class="pages">
			<div class="page page1">
				<span class="tally_blue_RGB"><img src="assets/images/sign-doc/tally_blue_RGB.png" ></span>
				<h3 i18n>MASTER SERVICES AGREEMENT</h3>
				<p><span i18n>This Services Agreement (the “</span><strong i18n>Agreement</strong><span i18n>”) sets forth terms under which company (“</span><strong i18n>Company</strong><span i18n>”) shall provide services to client, a (the “</span><strong i18n>Client</strong><span i18n>”). This Agreement is effective as of August 30, 2018 (“</span><strong i18n>Effective Date</strong><span i18n>”). </span></p>
				<p><span i18n>1. </span><strong i18n>Services</strong><span i18n>. Company shall provide services to the Client as described on one or more Statements of W ork signed by Company and Client that reference this Agreement (“</span><strong i18n>SOW</strong><span i18n>” or “</span><strong i18n>Statement of Work</strong><span i18n>”).Company shall perform Services in a prompt manner and have the final service (“</span><strong i18n>Deliverable</strong><span i18n>”) ready for Client no later than the due date specified in the applicable SOW (“</span><strong i18n>Completion Date</strong><span i18n>”). This due date is subject to change in accordance with the Change Order process defined in the applicable SOW. Client shall assist Company by promptly providing all information requests known or available and relevant to the Services in a timely manner. </span></p>
				<p><span i18n>2. </span><strong i18n>Contract Price</strong>.<span i18n> For performance of the Services and rendering the Deliverable, Client shall pay to Company all fees due under the applicable SOW. </span></p>
				<p><span i18n>3. </span><strong i18n>Deposit</strong>.<span i18n> An initial payment (the “</span><strong i18n>Deposit</strong><span i18n>”) of is due to Company at signing. 50% of the Deposit shall be non-refundable in the event that Client cancels the project prior to its completion.</span> </p>
				<p><span i18n>4. </span><strong i18n>Dates of Performance</strong><span i18n>. Company will begin performing services upon receipt of signed Agreement and Deposit. Unless terminated as provided in this Agreement, Company will complete Services by the Completion Date. Deliverable shall be furnished to Client within 72 hours of final payment for the Services. </span></p>
				<p><span i18n>5. </span><strong i18n>Change in Services</strong><span i18n>. If Client desires changes to the SOW, Client shall submit to Company a written request in accordance with the change order process defined in the applicable SOW. The parties may execute additional Statements of Work describing Services, which will become part of this Agreement upon execution by Company and the Client. If additional SOW is executed, then Client shall pay Company for all services performed prior to the additional SOW before Company begins work on the new SOW. </span></p>
				<p><span i18n>6. </span><strong i18n>Termination</strong><span i18n>. Company shall have the right to modify, reject, or terminate any SOW and any related work in process with five days written notice to Client. In the event Company terminates the SOW prior to completion of Services, the Client shall pay Company the fees due under the SOW with respect to Services completed as of the date of termination. Payment for completed work will be deducted from the deposit. Company will retain the non- refundable 50% of the Deposit and return any unearned portion exceeding 50% of the Deposit. Any amount due for services performed by Company above the deposit will be billed to Client and Client shall promptly pay. Upon settlement of funds due to Company, all Client provided materials will be returned to Client and all Client use rights in the work in process as described in Section 9 will be transferred to Client. </span></p>
				<p i18n>Accepted and agreed to as of the Effective Date by the authorized representative of each party: </p>				
			</div>
			<div class="page page2" #scroll2mobile>
				<p i18n>Accepted and agreed to as of the Effective Date by the authorized representative of each part:</p>
				<table class="table_box">
					<tr>
						<th i18n>CLIENT</th>
						<th i18n>COMPANY</th>
					</tr>
					<tr>
						<td class="signin"><label i18n>Signature </label>
							<span class="sign_img sign_img1"style="opacity: 0">
								<img src="assets/images/sign-doc/sign.svg"> 
								<img class="drag" src="assets/images/sign-doc/drag.svg">
							</span>
						</td>
						<td i18n>Signature</td>
					</tr>	
					<tr #scroll2>
						<td class="print_name"><label i18n>Print Name</label> <span class="input_field" [@fadetextEffect] = "textState"><input type="" name="" placeholder="Text"></span></td>
						<td i18n>Print Name</td>
					</tr>	
					<tr>
						<td class="print_title"><label i18n>Print Title </label><span class="input_field" [@fadetextEffect] = "textState"><input type="" name="" placeholder="Text"></span></td>
						<td i18n>Print Title</td>
					</tr>
					<tr>
						<td class="data_sign"><label i18n>Date Signed</label> <span class="date_Signed" [@fadetextEffect] = "textState">Date Signed </span></td>
						<td i18n>Date Signed</td>
					</tr>	
					<tr>
						<td class="notice"><label i18n>Notice Address</label> <span class="input_field"[@fadetextEffect] = "textState"><input type="" name="" placeholder="Text"></span></td>
						<td i18n>Notice Address</td>
					</tr>	
					<tr>
						<td class="email_td"><label i18n>Email</label> <span class="email" [@fadetextEffect] = "textState" (@fadetextEffect.done)="animationfadetextEffect($event)" i18n>Email </span></td>
						<td i18n>Email</td>
					</tr>	
					<tr>
						<td><label i18n>Primary Contact</label> </td>
						<td i18n>Primary Contact</td>
					</tr>	
					<tr>
						<td class="blue_signin"><label i18n>Legal Approval</label> 
							<span class="sign_img sign_img2" style="opacity: 0">
								<img src="assets/images/sign-doc/sign-cyan.svg">
								<img class="drag" src="assets/images/sign-doc/drag.svg">
							</span>
						</td>
						<td i18n>Legal Approval</td>
					</tr>	
				</table>
			</div>
			<div class="page page3">
				<!-- <img src="assets/images/sign-doc/page3.jpg"> -->
				<div class="Invoice_box">
					<div class="Invoice_box_inner">
						<div class="address2">
							<div class="tally_title tally_blue_RGB" i18n><img src="assets/images/sign-doc/tally_blue_RGB.png" ></div>
							<div class="address">
								<div class="left" i18n>From</div>
								<div class="right">
									<span i18n>Tally</span>
									<span i18n>	123 Main St.</span>
									<span i18n>Suite 1800</span>
									<span i18n>Seattle, WA 98122</span>
								</div>
							</div>
						</div>
						<div class="address1">
							<div class="left_box address">
								<div class="left" i18n>Invoice For</div>
								<div class="right">
									<span i18n>Acme Realty Co.</span>
									<span i18n>321 First Street</span>
									<span i18n>Seattle, WA 98122</span>
								</div>
							</div>
							<div class="right_box">
								<div class="left">
									<span i18n>Invoice ID</span>
									<span i18n>PO Number</span>
									<span i18n>Issue Date</span>
									<span i18n>Due Date</span>
								</div>
								<div class="right">
									<span i18n>00006</span>
									<span i18n>123456</span>
									<span i18n>04/21/20</span>
									<span i18n>05/21/20</span>
								</div>
							</div>
						</div>
						<div class="address1 address3" #scroll3>
							<div class="left_box address">
								<div class="left" i18n>Subject</div>
								<div class="right">
									<span i18n>Staging Services</span>
								</div>
							</div>
							<div class="right_box">
							</div>
						</div>
					</div>	
				</div>
				<div class="page3_inner">
					<div class="page3_heading">
						<div class="left" i18n>Description</div>
						<div class="right" i18n>Amount</div>
					</div>
					<div class="page3_detail">
						<div class="left" i18n>Staging fee for interior and exterior of 301 Central Avenue home.</div>
						<div class="right">
							<ul>
								<li i18n>$4,000</li>
								<li class="amount">
									<div class="amount_inner" style="opacity: 0">
										<span class="amount_btn" [@shakeEffect]="shakestate" (@shakeEffect.done)="animationshakeEffect($event)" (click)="show_payment_popup()"><img src="assets/images/sign-doc/money.svg" i18n> $0.00 USD</span> <img class="drag" src="assets/images/sign-doc/drag.svg">
										<div class="user_box_left_popup_inner popup trigger_click" (click)="show_payment_popup()" [@step3Effect]="step3state">
											<span class="popup_titel" i18n>Easily collect payments when needed.</span>
											<span class="popup_dis"><span class="blue" i18n>Click on the field</span> to see customizable settings.</span>
											<div class="top_arrow"></div>
										</div>
										<div class="highlight" style="display: none;"></div>
									</div>
								</li>
								<li><span class="action_btn" i18n>Approve</span></li>
								<li><span class="action_btn" i18n>Decline</span></li>
							</ul>
						</div>
					</div>
					<div class="note_section">
						<h5 i18n>Amount Due</h5>
						<p class="note" i18n>Notes</p>
						<p class="note_dis" i18n>Thank you for your business. It's a pleasure to work with you.</p>
					</div>
				</div>

			</div>
		</div>
	</div>
	<div class="right">
		<div class="right_inner">
			<span class="thumb_dis"><img src="assets/images/sign-doc/select_down_arrow.svg"><span i18n>Tally Master Services Agreement.pdf</span></span>
			<span class="pages_no" i18n>Pages: 3</span>
			<ul>
				<li class="active"><img src="assets/images/sign-doc/thumbnail1.png"></li>
				<li><img src="assets/images/sign-doc/thumbnail2.png"></li>
				<li><img src="assets/images/sign-doc/thumbnail3.png"></li>
			</ul>
		</div>
	</div>
	<div class="button_footer">
		<div class="button_footer_inner">
			<div class="button_box"><a class="back" i18n>Back</a><a class="next">Next</a></div>
		</div>	
	</div>
</div>
<div class="popup_box" [@popupEffect]="popupState" (@popupEffect.done)="animationpopupdone($event)">
	<h3 class="" i18n>Setting up a document has never been easier.</h3>
	<p class="" i18n>Now, let’s see how a document gets sent.</p>
	<div class="button_box light_blue">
		<a routerLink="/review-send"><button class="" i18n>NEXT</button></a>
	</div>
</div>
<div class="payment_section" [@paymentEffect]="paymentState" (@paymentEffect.done)="animationpaymentEffect($event)">
	<div class="payment_section_inner">
		<div class="payment_heading"><img src="assets/images/sign-doc/money.svg"><span i18n>Payment Item</span></div>
		<div class="payment_gatway">
			<ul class="ul1">
				<li class="ul_heading"><span><span i18n>Payment Gateway</span><img src="assets/images/sign-doc/chevron-up.svg"></span></li>
				<li class="input_select"><span><span i18n>Payments Demo</span><img src="assets/images/sign-doc/select_down_arrow.svg"></span></li>
			</ul>
			<ul class="ul2">
				<li class="ul_heading"><span><span i18n>Payment Amount</span><img src="assets/images/sign-doc/chevron-up.svg"></span></li>
				<li class="input_select"><span><span i18n>USD</span><img src="assets/images/sign-doc/select_down_arrow.svg"></span></li>
				<li class="input" i18n>
					<span class="amount_input" [@amountInputEffect]="amtinpState" (@amountInputEffect.done)="animationamtinpEffect($event)"> <input type="" name="" placeholder></span>
					<span class="amount ampt-span" style="color: #BDBDBD; z-index: 5;" >Enter Amount</span>
					<span class="amount ampt-span ampt2" style="display:none;color: black; z-index: 5;" >4,000</span>
				</li>
				<li class="input_select"><span><span i18n>Fixed amount</span><img src="assets/images/sign-doc/select_down_arrow.svg"></span></li>
			</ul>
			<ul class="light_gray ul3">
				<li class="ul_heading"><span><span i18n>Payment Description</span><img src="assets/images/sign-doc/chevron-up.svg"></span></li>
				<li class="input"><span><span i18n>Item Name</span></span></li>
				<li class="input"><span><span i18n>Item Code / SKU</span></span></li>
				<li class="input"><span><span i18n>Item Details</span></span></li>
			</ul>
			<ul class="ul4">
				<li class="ul_heading"><span><span i18n>Formatting</span><img src="assets/images/sign-doc/chevron_down.svg"></span></li>
			</ul>
			<ul class="ul5">
				<li class="ul_heading"><span><span i18n>Data Label</span><img src="assets/images/sign-doc/chevron_down.svg"></span></li>
			</ul>
			<ul class="ul6">
				<li class="ul_heading"><span><span i18n>Field Order</span><img src="assets/images/sign-doc/chevron_down.svg"></span></li>
			</ul>
			<div class="del_btn"><span i18n>Delete</span></div>
		</div>

	</div>
</div>


<div class="overlay" (click)="add_Shake()" style="display: none;" ></div>
