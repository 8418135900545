<header>
	<div class="header_inner {{innerclass}}">
		<div class="left">
			<div class="cross">
				<img src="assets/images/Close.png">
			</div>
			<div class="left_text">
				<span i18n>Tally Master Services Agreement</span>
			</div>
		</div>
		<div class="middle">
			<ul>
				<li class={{addClass}}>
					<span i18n>Add</span>
				</li>
				<li class={{selectClass}}>
					<span i18n>Select</span>
				</li>
				<li class={{prepareClass}}>
					<span i18n>Prepare</span>
				</li>
				<li *ngIf="!showsend" class={{reviewClass}}>
					<span i18n>Review</span>
				</li>
				<li *ngIf="showsend" class={{sendClass}}>
					<span i18n>Send</span>
				</li>
			</ul>
		</div>
		<div class="right">
			<ul>
				<li class="img1"><img src="assets/images/notification.png"></li>
				<li class="img2"><img src="assets/images/help.png"></li>
				<li class="img3"><img src="assets/images/Medium.png"></li>
			</ul>
		</div>
	</div>
</header>
