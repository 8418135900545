import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { trigger,  state,  style,  animate,  transition, query, stagger, keyframes } from '@angular/animations';
import $ from "jquery";
import {AppComponent} from '../app.component';

@Component({
  	selector: 'app-sign-doc',
  	templateUrl: './sign-doc.component.html',
  	styleUrls: ['./sign-doc.component.css'],
  	animations: [
	    trigger('step1Effect', [
	    	state('initial', style({
	        	transform: 'translateX(17%)',
	        	display: 'none'
	      	})),
	      	state('hide', style({
	        	display: 'none'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('600ms ease-in-out')
	      	])
	    ]),
	    trigger('fadetextEffect', [
	    	state('initial', style({
	        	opacity : '0'
	      	})),
	      	transition('initial=>final',animate('600ms ease-in-out'))
	    ]),
	    trigger('step2Effect', [
	    	state('initial', style({
	        	transform: 'translateX(70px)',
	        	display: 'none',
	        	opacity : '0'
	      	})),
	      	state('hide', style({
	      		transform: 'translateX(60px)',
	        	opacity: '0'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('600ms ease-in-out')
	      	]),
	      	transition('final=>hide',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('600ms ease-in-out')
	      	])
	    ]),
	    trigger('dropEffect', [
	    	state('initial', style({
	        	transform : 'scaleY(0)',
	        	opacity: '0'
	      	})),
	      	transition('final=>initial',animate('1000ms ease-in-out')),
	      	transition('initial=>final',animate('1000ms ease-in-out'))
	    ]),
	    trigger('tickEffect', [
	    	state('final', style({
	        	transform : 'translateY(25px)'
	      	})),
	      	transition('initial=>final',animate('1000ms ease-in-out'))
	    ]),
	    trigger('step3Effect', [
	    	state('initial', style({
	        	transform: 'translateY(65px)',
	        	display: 'none'
	      	})),
	      	state('hide', style({
	        	display: 'none'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('600ms ease-in-out')
	      	])
	    ]),
	    trigger('paymentEffect', [
	    	state('initial', style({
	        	transform: 'translateX(240px)',
	        	opacity: '0'
	      	})),
	      	state('hide', style({
	      		transform: 'translateX(240px)',
	        	display: 'none'
	      	})),
	      	transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	animate('400ms ease-in-out')
	      	]),
	      	transition('final=>hide',animate('400ms ease-in-out'))
	    ]),
	    trigger('amountInputEffect', [
	    	
	    ]),
	    trigger('highlightEffect', [
      		state('initial', style({
        		height:'47px'
      		})),
      		transition('initial=>final', animate('1000ms ease-in-out')),
    	]),
    	trigger('popupEffect', [
      		state('initial', style({
        		transform: 'translate(-50%,-30%)',
	        	opacity: '0',
	        	display: 'none'
      		})),
      		transition('initial=>final',[
		      	style({
		      		display:'block'
		      	}),
		      	 animate('600ms ease-in-out')
		    ]),
    	]),
    	trigger('shakeEffect', [
      		transition('initial=>shake', animate('400ms', keyframes([
	      	  style({ transform: 'translateX(0)' }),
	          style({ transform: 'translateX(-10px)' }),
	          style({ transform: 'translateX(0)' }),
	          style({ transform: 'translateX(10px)' }),
	          style({ transform: 'translateX(0)' }),
	        ])))
    	]),
   	]
})
export class SignDocComponent implements OnInit {
	pagestate = 'initial';
	step1state = 'initial';
	sign1state = 'initial';
	textState = 'initial';
	sign2state = 'initial';
	step2state = 'initial';
	dropstate = 'initial';
	tickstate = 'initial';
	amountstate = 'initial';
	step3state = 'initial';
	paymentState = 'initial';
	amtinpState = 'initial';
	priceinpState = 'initial';
	highlightState = 'initial';
	popupState = 'initial';
	shakestate = 'initial';
	@ViewChild('scroll2') scroll2: ElementRef;
	@ViewChild('scroll3') scroll3: ElementRef;
	@ViewChild('scroll2mobile') scroll2mobile: ElementRef;

  	constructor(public app: AppComponent) { }

  	ngOnInit(): void {
  		window.scroll({ 
	      top: 0, 
	      left: 0, 
	      behavior: 'auto' 
    	});
  	}

  	ngAfterViewInit() {
  		this.app.timerId.push(
	  		setTimeout(()=>{
	  			$('.overlay').css('background','rgba(0, 0, 0, .4)');
	  			$('.highlight_black').css('background','rgba(0, 0, 0, 0.4)');
	   			$('.overlay').fadeIn();
	   			$('.tab_menu  .highlight').fadeIn();
	   			$('.highlight_black').fadeIn();
	      		this.pagestate = 'scroll1';
	      		this.step1state = 'final';
	   		},2000)
	   	);
  	}

  	animationstep1Effect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			this.app.timerId.push(
		  		setTimeout(()=>{
		      		$('.overlay').fadeOut();
		  			$('.tab_menu  .highlight').fadeOut();
	   				$('.highlight_black').fadeOut();
	   				this.step1state = 'hide';
	   				if(this.app.isMobile || this.app.isTablet ){
	   					this.scroll2mobile.nativeElement.scrollIntoView({ behavior: 'smooth',block : 'center' });
	   				}else{
	   					this.scroll2.nativeElement.scrollIntoView({ behavior: 'smooth',block : 'center' });
	   				}
	   				$('.right_inner ul li').eq(1).addClass('active');
	   				$('.right_inner ul li').eq(0).removeClass('active');
		   		},3500)
		   	);
		   	this.app.timerId.push(
		   		setTimeout(()=>{
	   				var imgtodrag = $('ul.side_menu.menu1 li.signature span.yellow');
	   				var imgclone = $('.sign_img1').clone()
	                .offset({
	                	top: imgtodrag.offset().top,
	                	left: imgtodrag.offset().left - 50
	            	}).css({
	                	'opacity': '1',
	                    'position': 'absolute',
	                    'z-index': '4',
	                    'display':'none'
	            	}).appendTo($('body'));
	            	imgclone.first().css({'display':'block'});
	            	imgclone.animate({
	                	'top': $('.sign_img1').offset().top,
	                    'left': $('.sign_img1').offset().left,
	            	}, 600,
	            	function(){
		            	setTimeout(()=>{
		            		imgclone.remove();
				  			$('.sign_img1').removeAttr('style');
			   				$('.sign_img1 img.drag').fadeOut('600');
				   		},1000)
		            });
		            setTimeout(()=>{
		   				this.textState = 'final';
			   		},2600)
		   		},5000)
		   	);
  		}
  	}

  	animationfadetextEffect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			this.app.timerId.push(
		  		setTimeout(()=>{
	   				$('.overlay').fadeIn();
	   				//$('.drop_box  .highlight').css('height','47px');
	   				$('.tab_menu_inner').css('z-index','0');
		   			$('.drop_box  .highlight').fadeIn();
		   			$('.highlight_black').fadeIn();
		   			$('div.top_header').css('z-index',5).fadeIn();
		   			$('.top_header_overlay').fadeIn();
		   			this.step2state = 'final';
		   		},1500)
		   	);
  		}
  	}

  	animationstep2Effect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			this.app.timerId.push(
		  		setTimeout(()=>{
	   				this.dropstate = 'final';
	   				this.highlightState = 'final';
		   		},3500)
		   	);
  		}
  	}
  	animationtickEffect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			$('.top_name .circle').addClass('cyan').fadeIn();
  			$('.top_name .text').html('Blake Hayes').fadeIn();
  			$('.tab_menu_inner').addClass('checked').fadeIn();
  			this.app.timerId.push(
		  		setTimeout(()=>{
	   				this.dropstate = 'initial';
		   		},1500)
		   	);
  		}
  	}
  	animationdropEffect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			this.app.timerId.push(
		  		setTimeout(()=>{
		  			$('.overlay').fadeOut();
		   			$('.drop_box  .highlight').fadeOut();
		   			$('.highlight_black').fadeOut();
		   			$('.top_header_overlay').fadeOut();
		   			this.step2state = 'hide';
	   				this.tickstate = 'final';
	   				$('.left_inner .Standard_Selected').fadeOut();
	   				$('.left_inner .Standard_Selected_blue').fadeIn();
		   		},1500)
		   	);
  		}
  		if($event.fromState == 'final' && $event.toState == 'initial'){
  			this.app.timerId.push(
		  		setTimeout(()=>{
		  			$('div.top_header').removeAttr('style');
		  			var imgtodrag = $('ul.side_menu.menu1 li.signature span.yellow');
	   				var imgclone = $('.sign_img2').clone()
	                .offset({
	                	top: imgtodrag.offset().top,
	                	left: imgtodrag.offset().left - 50
	            	}).css({
	                	'opacity': '1',
	                    'position': 'absolute',
	                    'z-index': '4',
	                    'display':'none'
	            	}).appendTo($('body'));
	            	imgclone.first().css({'display':'block'});
	            	imgclone.animate({
	                	'top': $('.sign_img2').offset().top,
	                    'left': $('.sign_img2').offset().left,
	            	}, 600,function(){
	            		setTimeout(()=>{
	            			imgclone.remove();
				  			$('.sign_img2').removeAttr('style');
			   				$('.sign_img2 img.drag').fadeOut('600');
				   		},1000)
				   	});
				   	setTimeout(()=>{
		   				$('.top_name .circle').removeClass('cyan');
			   			$('.top_name .text').html('Mary Wilson');
		   				//this.pagestate = 'scroll3';
		   				this.scroll3.nativeElement.scrollIntoView({ behavior: 'smooth',block : 'center' });
		   				this.pagestate = 'void';
		   				$('.left_inner .Standard_Selected').fadeIn();
		   				$('.left_inner .Standard_Selected_blue').hide();
		   				$('.tab_menu_inner').removeClass('checked').fadeIn();
		   				$('.right_inner ul li').eq(2).addClass('active');
		   				$('.right_inner ul li').eq(1).removeClass('active');
			   		},3000)
			   		setTimeout(()=>{
			   			$('.amount_btn').css('border','2px solid #4186EC');
			   			var imgtodrag = $('ul.side_menu.menu2 li.paymentItems span.yellow');
			            var imgclone = $('.amount_inner').clone()
	                .offset({
	                	top: imgtodrag.offset().top,
	                	left: imgtodrag.offset().left
	            	}).css({
	                	'opacity': '1',
	                    'position': 'absolute',
	                    'z-index': '4',
	                    'display':'none'
	            	}).appendTo($('body'));
	            	imgclone.find('span:first').css({
	            		'font-size': '9px',
					    'background': 'rgba(255, 214, 91, 0.75)',
					    'border': '1.5px solid #DBC071',
					    'border-radius': '3px',
					    'display': 'inline-block',
					    'width': '100%',
					    'text-align': 'center',
					    'height': '25px',
					    'line-height': '25px'
	            	});
	            	imgclone.find('img.drag').css({
	            		'position': 'absolute',
				        'right': 0,
				        'top': '5px',
				        'width': '20px',
				        'z-index': 2
	            	});
	            	imgclone.find('div.highlight').css({
		            	'top': '-5px',
					    'height': '40px',
					    'width': '113px',
					    'left': '-12px'
	            	});
	            	imgclone.first().css({'display':'block'});
	            	imgclone.animate({
	                	'top': $('.amount_inner').offset().top,
	                    'left': $('.amount_inner').offset().left,
	            	}, 600,function(){
	            		$('.amount_inner').removeAttr('style');
	            		setTimeout(()=>{
	            			imgclone.remove();
			   				$('.amount_inner img.drag').fadeOut('600');
			   				$('.amount_btn').css('border','2px solid #DBC071');
				   		},1000)
				   	});
				   	setTimeout(()=>{
		   				$('.overlay').fadeIn();
			   			$('.amount_inner  .highlight').fadeIn();
			   			$('.highlight_black').fadeIn();
			   			this.step3state = 'final';
			   			$('span.amount_btn').css('cursor','pointer');
			   		},3500)
			   		},4500)
		   		},1500)
		   	);
  		}
  	}

  	show_payment_popup(){
		this.paymentState = 'final';
		$('.amount_inner img.drag').fadeIn('600');
		$('.amount_btn').css('border','2px solid #4186EC');
  	}

  	animationpaymentEffect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
			this.amtinpState = 'final';
	   	}
  	}

  	animationamtinpEffect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
  			this.app.timerId.push(
	  			setTimeout(()=>{
	  				$('ul.ul2 li.input span.ampt-span').fadeOut();
	   				$('ul.ul2 li.input span.ampt-span.ampt2').fadeIn('3000');
	   				$('.amount_inner .amount_btn').html('$4,000 USD');
	   				$('.amount_inner .amount_btn').fadeIn('3000');
	   				$('.amount_inner .highlight').animate({ width: $('.amount_inner .highlight').width() + 35 }, 'slow');
		   		},1000)
		   	);
		   	this.app.timerId.push(
		   		setTimeout(()=>{
	   				this.step3state = 'hide';
	   				this.paymentState = 'hide';
	   				$('.amount_inner img.drag').fadeOut('600');
	   				$('.amount_btn').css('border','1.5px solid #DBC071');
	   				$('.overlay').fadeOut();
		   			$('.amount_inner  .highlight').fadeOut();
		   			$('.highlight_black').fadeOut();
		   			$('.overlay').removeAttr('style');
		   			$('.highlight_black').removeAttr('style');
		   			$('.amount').css('z-index',3);
		   		},3000)
		   	);
		   	this.app.timerId.push(
		   		setTimeout(()=>{
	   				$('.overlay').fadeIn();
		   			this.popupState = 'final';
		   			$('.highlight_black').fadeIn();
		   		},5000)
		   	);
  		}
  	}

  	add_Shake(){
  		if(this.paymentState == 'initial'){
  			this.shakestate = 'shake';
  		}
  	}
  	animationshakeEffect($event){
  		if($event.fromState == 'initial' && $event.toState == 'shake'){
  			this.shakestate = 'initial';
  		}
  	}

  	animationpopupdone($event){
      if($event.fromState == 'initial' && $event.toState == 'final'){
        this.app.showRefreshBtn = true;
      }
    }
}
