import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router,Event, NavigationEnd } from '@angular/router';
import { LocationStrategy  } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  	title = 'docuSign';
  	deviceInfo = null;
  	isMobile:Boolean;
  	isTablet:Boolean;
    showRefreshBtn:Boolean =true;
    showRefreshBtn2:Boolean = true;
    isShown:Boolean =true;
    timerId:number[] = [];
    currentpage;
    mobilecss : string;
    tabletcss : string;

  	constructor(private deviceService: DeviceDetectorService, private router: Router,location: LocationStrategy,public sanitizer: DomSanitizer) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.timerId.forEach(id => clearTimeout(id)); 
      location.onPopState(() => { 
        this.timerId.forEach(id => clearTimeout(id)); 
        this.showRefreshBtn = false;
        this.showRefreshBtn2 = false;
      }); 
      router.events.subscribe( (event: Event) => {
        this.currentpage = this.router.url;
        if (event instanceof NavigationEnd) {
            this.showRefreshBtn =false;
            this.showRefreshBtn2 =false;
        }
      });

    }

  	ngOnInit(): void {
        this.mobilecss = 'assets/css/mobile-css.css';
        this.tabletcss = 'assets/css/tablet-css.css';
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
  	}
    restart_section($event){
      this.timerId.forEach(id => clearTimeout(id)); 
      this.timerId= [];
      $event.preventDefault();
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
}
