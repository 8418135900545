<app-header></app-header>
<div class="container_box">
	<div class="container_box_inner">
		<div class="left">
			<div class="left_inner">
			<h3>Review and Send</h3>
			<div class="subHeading"><h4>Message to Recipients</h4><span>Add a private message</span></div>
				<div class="form_section">
					<div class="form_box">
						<div class="form_field">
							<label>Subject line</label>
							<input type="" name="" placeholder="Please DocuSign: Tally Master Services Agreement.">
						</div>
						<div class="form_field">
							<label>Message</label>
							<textarea type="" name="" placeholder="Please DocuSign Tally Master Services Agreement.&#x0a;&#x0a;Thank You,&#x0a;&#x0a;Larry Lee"></textarea>
						</div>
					</div> 
					<div class="highlight" style="display: none;"></div>
					<div class="user_box_left_popup_inner popup" [@step1Effect]="step1State" (@step1Effect.done)="animationstep1done($event)">
						<span class="popup_titel_subHeading">Step 1</span>
						<span  class="popup_titel" >Customize your email.</span>
						<span class="popup_dis">	
							<span class="">Provide context and a personal touch.</span>
						</span>
						<div class="top_arrow"></div>
					</div>
				</div>
				<div class="send_container" style="z-index: 0">
					<div class="send_container">
						<div class="send_box"><img class="drag" class="notchecked" src="assets/images/Review_and_Send/checkbox.svg"><img class="drag" src="assets/images/Review_and_Send/checked.svg" class="checked" style="display: none;"><span>Send automatic reminders</span> <span class="every_day" style="display: none;"><span class="every_text">every 2 days</span><img class="drag" src="assets/images/Review_and_Send/every_day.svg"></span></div>
					</div>
					<div class="highlight" style="display: none;"></div>
					<div class="user_box_left_popup_inner popup" [@step2Effect]="step2State" (@step2Effect.done)="animationstep2done($event)">
						<span class="popup_titel_subHeading">Step 2</span>
						<span  class="popup_titel">Set reminders to keep it on track.</span>
						<span class="popup_dis">	
							<span class="">Sometimes recipients need a gentle nudge.</span>
						</span>
						<button class="popup_btn" i18n (click)="(show_next())">next</button>
						<div class="top_arrow"></div>
					</div>
				</div>
				<div class="button_section">
					<div class="left"><button>Preview</button></div>
					<div class="right"><button class="back">Back</button>
						<span class="next_btn" style="z-index: 0;"><button class="next" [@shakeEffect]="shakestate" (@shakeEffect.done)="animationshakeEffect($event)" (click)="Show_popup()">Send</button>
							<div class="highlight" style="display: none;"></div>
							<div class="user_box_left_popup_inner popup trigger_click" (click)="Show_popup()" [@step3Effect]="step3State" >
								<span class="popup_titel">Let’s get the deal done.</span>
								<span class="popup_dis">	
									<span class="popup_dis_inner"><span>Click</span> <span class="blue">Send</span> <span>to deliver the document.</span></span>
								</span>
								<div class="top_arrow"></div>
							</div>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="right">
			<div class="right_inner">
				<ul>
					<li class="active"><span>SUMMARY</span></li>
					<li><span>OPTIONS</span></li>				
				</ul>
				<div class="right_box">
					<span class="doc">Documents</span><span class="edit">Edit</span>
					<p>Tally Master Services Agreement.pdf</p>
				</div>
				<div class="Recipients_box">
					<div class="Recipients_box_inner">
						<span class="recip">Recipients</span><span class="edit">Edit</span>
					</div>
					<div class="Recipients_list list1">
						<div class="left">
							<div class="prfile_image">
								<img class="drag" src="assets/images/Review_and_Send/profile1.png">
							</div>
							<div class="prfile_detial">
								<span class="profile_name">Mary Wilson</span>
								<span class="profile_email">mary.wilson@mail.com</span>
							</div>
						</div>
						<div class="right">
							<img class="drag" src="assets/images/Review_and_Send/Signature.svg"><span>Needs to Sign</span>
						</div>
					</div>
					<div class="Recipients_list">
						<div class="left">
							<div class="prfile_image">
								<img class="drag" src="assets/images/Review_and_Send/profile2.png">
							</div>
							<div class="prfile_detial">
								<span class="profile_name">Blake Hayes</span>
								<span class="profile_email">blake.hayes@mail.com</span>
							</div>
						</div>
						<div class="right">
							<img class="drag" src="assets/images/Review_and_Send/Signature.svg"><span>Needs to Sign</span>
						</div>
					</div>
				</div>
				<div class="discription">
					<p>Once the document is completed, all recipients will receive a copy of the completed documents.</p>
				</div>
			</div>
		</div>
	</div>
</div>	
<div class="popup_box" [@popupEffect]="popupState" (@popupEffect.done)="animationpopupdone($event)">
	<h3 class="">Your document has now been sent.</h3>
	<p class="">Now, let’s see how simple and effortless the signing experience is.</p>
	<div class="button_box">
		<a routerLink="/mobile-recieve"><button class="">NEXT</button></a>
	</div>
</div>

<div class="overlay" (click)="add_Shake()" style="display: none;"></div>
