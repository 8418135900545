import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  innerclass = '';
	addClass = '';
	selectClass = '';
	prepareClass = '';
	reviewClass = '';
  sendClass = '';
  showsend:Boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
  	if(this.router.url == "/"){
  		this.addClass = 'past';
  		this.selectClass = 'upcoming';
		this.prepareClass = 'upcoming';
		this.reviewClass = 'upcoming';
  	}else if(this.router.url == "/select-recipent"){
  		this.addClass = 'current';
  		this.selectClass = 'past';
		  this.prepareClass = 'upcoming';
		  this.reviewClass = 'upcoming';
  	}else if(this.router.url == "/sign-doc"){
      this.innerclass = 'sticky';
      this.addClass = 'current';
      this.selectClass = 'current';
      this.prepareClass = 'past';
      this.reviewClass = 'upcoming';
    }else if(this.router.url == "/review-send"){
      this.showsend = true;
      this.addClass = 'current';
      this.selectClass = 'current';
      this.prepareClass = 'current';
      this.sendClass = 'send';
    }
  }

}
