<div class="container mobile-recieve-container" (click)="add_Shake()">
	<div class="row">

		<div class="images_box image_box1">
			<div class="image_box" [@mobile1Effect]="mobile1State" (@mobile1Effect.done)="animationmobile1done($event)"><img src="assets/images/mobile-recieve/iphone-group.png"></div>
			<div class="mobile_msg">
				<div class="mobile_top" [@msgEffect]="msgState">
					<img src="assets/images/mobile-recieve/new-notification.png">
				</div>
				<div class="user_box_left_popup_inner popup" [@step1Effect]="step1State" (@step1Effect.done)="animationstep1done($event)">
					<span  class="popup_titel" i18n>Signing is always free, no account needed.</span>
					<span class="popup_dis">	
						<span class="" i18n>Just a couple clicks to start agreeing.</span>
					</span>
					<div class="top_arrow"></div>
				</div>
			</div>
		</div>
		<div class="images_box image_box2" style="display: none">
			<div class="image_box"><img src="assets/images/mobile-recieve/iphone-group2.png"></div>
			<div class="mobile_msg">
				<div class="user_box_left_popup_inner popup" [@step2Effect]="step2State">
					<span  class="popup_titel" i18n>Brand your emails.</span>
					<span class="popup_dis">	
						<span class="" i18n>Set up custom branding for a customized touch.</span>
					</span>
					<button class="blue_next_btn" (click)="show_documnet()">Next</button>
					<div class="top_arrow"></div>
				</div>
			</div>
		</div>
		<div class="image_box3" style="display: none;">
			<div class="image_box">
				<img src="assets/images/mobile-recieve/blank_phone.png">
			</div>
			<div class="top_header" [@mobile3Effect]="mobile3State">
				<div class="blue_highlighted"></div>
				<div class="hamburg">
					<img src="assets/images/mobile-recieve/menu.svg">
				</div>
				<div class="top_header_right">
					<span class="top_header_text" i18n>Select Start to begin</span>
					<span class="top_header_button"><button>Start</button></span>
				</div>
				<div class="top_header_right top_header_right1" style="display: none;">
					<span class="top_header_text text_sign" i18n>Select Sign Here to sign</span>
					<span class="top_header_text text_payment" i18n style="display: none;">Payment Item</span>
					<span class="top_header_button" ><button i18n>Next</button></span>
				</div>
				<div class="top_header_right top_header_right2" style="display: none;">
					<span class="top_header_button">
						<button i18n>Next</button>
						<img [@circleEffect]="circleState" (@circleEffect.done)="animationcircledone($event)" src="assets/images/mobile-recieve/curcor.png">
					</span>

				</div>
			</div>
			<div class="text_container text_container_box1" [@mobile3Effect]="mobile3State">
				<div class="text_container_inner">
					<div class="pages">
						<div class="page page1">
							<span class="tallyimg"><img src="assets/images/mobile-recieve/tally2.png"></span>
							<h3 i18n>MASTER SERVICES AGREEMENT</h3>
							<p><span i18n>This Services Agreement (the “</span><strong>Agreement</strong><span i18n>”) sets forth terms under which company (“</span><strong>Company</strong><span i18n>”) shall provide services to client, a (the “</span><strong>Client</strong><span i18n>”). This Agreement is effective as of August 30, 2018 (“</span><strong>Effective Date</strong><span i18n>”). </span></p>
							<p><span i18n>1. </span><strong>Services</strong><span i18n>. Company shall provide services to the Client as described on one or more Statements of Work signed by Company and Client that reference this Agreement (“</span><strong>SOW</strong><span i18n>” or “</span><strong>Statement of Work</strong><span i18n>”).Company shall perform Services in a prompt manner and have the final service (“</span><strong>Deliverable</strong><span i18n>”) ready for Client no later than the due date specified in the applicable SOW (“</span><strong>Completion Date</strong><span i18n>”). This due date is subject to change in accordance with the Change Order process defined in the applicable SOW. Client shall assist Company by promptly providing all information requests known or available and relevant to the Services in a timely manner.</span> </p>
							<p><span i18n>2. </span><strong>Contract Price</strong><span i18n>. For performance of the Services and rendering the Deliverable, Client shall pay to Company all fees due under the applicable SOW. </span></p>
							<p><span i18n>3. </span><strong>Deposit</strong><span i18n>. An initial payment (the “</span><strong>Deposit</strong><span i18n>”) of is due to Company at signing. 50% of the Deposit shall be non-refundable in the event that Client cancels the project prior to its completion. </span></p>
							<p><span i18n>4. </span><strong>Dates of Performance</strong><span i18n>. Company will begin performing services upon receipt of signed Agreement and Deposit. Unless terminated as provided in this Agreement, Company will complete Services by the Completion Date. Deliverable shall be furnished to Client within 72 hours of final payment for the Services. </span></p>
							<p><span i18n>5. </span><strong>Change in Services</strong><span i18n>. If Client desires changes to the SOW, Client shall submit to Company a written request in accordance with the change order process defined in the applicable SOW. The parties may execute additional Statements of Work describing Services, which will become part of this Agreement upon execution by Company and the Client. If additional SOW is executed, then Client shall pay Company for all services performed prior to the additional SOW before Company begins work on the new SOW. </span></p>
							<p><span i18n>6. </span><strong>Termination</strong><span i18n>. Company shall have the right to modify, reject, or terminate any SOW and any related work in process with five days written notice to Client. In the event Company terminates the SOW prior to completion of Services, the Client shall pay Company the fees due under the SOW with respect to Services completed as of the date of termination. Payment for completed work will be deducted from the deposit. Company will retain the non- refundable 50% of the Deposit and return any unearned portion exceeding 50% of the Deposit. Any amount due for services performed by Company above the deposit will be billed to Client and Client shall promptly pay. Upon settlement of funds due to Company, all Client provided materials will be returned to Client and all Client use rights in the work in process as described in Section 9 will be transferred to Client.</span></p>
							<p i18n>Accepted and agreed to as of the Effective Date by the authorized representative of each party: </p>				
						</div>
						<div class="page page2" #target>
							<p i18n>Accepted and agreed to as of the Effective Date by the authorized representative of each part:</p>
							<table class="table_box">
								<tr class="field1">
									<th i18n>CLIENT</th>
									<th i18n>COMPANY</th>
								</tr>
								<tr class="field2">
									<td><label i18n>Signature </label>
										<span [@scaleSignEffect]="scaleSignState" (@scaleSignEffect.done)="animationscaleSignEffect($event)" class="sign_img sign_img1" (click)="show_sign()">
											<span class="required" i18n>Required - Sign Here</span>
											<img class="signicon" src="assets/images/mobile-recieve/sign.svg"> 
											<img class="signimg" src="assets/images/mobile-recieve/sign_img.png" style="display: none"> 
										</span>
										<div class="highlight" style=""></div>
									</td>
									<td i18n>Signature</td>
								</tr>	
								<tr class="field3">
									<td><label i18n>Print Name</label> <div class="right"><span class="required" i18n>Required</span><span class="input_field"></span></div></td>
									<td i18n>Print Name</td>
								</tr>	
								<tr class="field4">
									<td><label i18n>Print Title</label> <div class="right"><span class="required" i18n>Required</span><span class="input_field"></span></div></td>
									<td i18n>Print Title</td>
								</tr>
								<tr class="field5">
									<td><label i18n>Date Signed</label><div class="right"><span class="required" i18n>Required</span><span class="input_field"></span></div></td>
									<td i18n>Date Signed</td>
								</tr>	
								<tr class="field6">
									<td><label i18n>Notice Address </label><div class="right"><span class="required" i18n>Required</span><span class="input_field"></span></div></td>
									<td i18n>Notice Address</td>
								</tr>	
								<tr class="field7">
									<td><label i18n>Email </label><div class="right"><span class="required" i18n>Required</span><span class="input_field"></span></div></td>
									<td i18n>Email</td>
								</tr>	
								<tr class="field8">
									<td i18n>Primary Contact </td>
									<td i18n>Primary Contact</td>
								</tr>	
								<tr class="field9">
									<td><label i18n>Legal Approval </label>
										<span class="sign_img sign_img2">
											<img src="assets/images/mobile-recieve/sign-cyan.svg">
										</span>
									</td>
									<td i18n>Legal Approval</td>
								</tr>	
							</table>
						</div>
						<div class="page page3">
							<div class="invoice1">
								<div class="left">
									<span class="from" i18n>From</span>
								</div>
								<div class="right">
									<span class="title" i18n>Tally</span>
									<span i18n>123 Main St.</span>
									<span i18n>Suite 1800</span>
									<span i18n>Seattle, WA </span>
									<span i18n >98122</span>
								</div>
							</div>
							<div class="page page4" >
								<div class="invoice1 left" #target1>
									<div class="left">
										<span class="from" i18n>For</span>
									</div>
									<div class="right">
										<span class="first_line" i18n>321 First Street</span>
										<span i18n>Seattle, WA</span>
										<span i18n>98122</span>
									</div>
								</div>
								<div class="invoice1 right">
									<div class="left">
										<span class="from" i18n>PO Number</span>
										<span class="from" i18n>Issue Date</span>
										<span class="from" i18n>Due Date</span>
									</div>
									<div class="right">
										<span i18n>123456</span>
										<span i18n>04/21/20</span>
										<span i18n>05/21/20</span>
									</div>
								</div>
								<div class="invoice1 subject">
									<div class="left">
										<span class="from" i18n>Subject</span>
									</div>
									<div class="right">
										<span i18n>Staging Services</span>
									</div>
								</div>
							</div>
							<div class="page3_inner page5">
								<div class="page3_heading">
									<div class="left" i18n>Description</div>
									<div class="right" i18n>Amount</div>
								</div>
								<div class="page3_detail">
									<div class="left" i18n>Staging fee for interior and exterior of 301 Central Avenue home.</div>
									<div class="right">
										<ul>
											<li i18n>$4,000</li>
											<li class="amount">
												<div class="amount_inner">
													<span class="required" i18n>Required</span>
													<span class="amount_btn"><img src="assets/images/mobile-recieve/money.svg" i18n> $4,000 USD</span>
												</div>
											</li>
										</ul>
										<div class="approve_decline" style="transform-origin: right top;" [@approveEffect]="approveState" (@approveEffect.done)="animationapprovedone($event)">
											<div class="unchecked" [@fadeaproveEffect]="fadeaproveState">Required - Approve - Unchecked</div>
											<ul>
												<li><span class="action_btn" i18n>Approve</span> <div class="tick"><img src="assets/images/mobile-recieve/tick_white.svg"></div></li>
												<li><span class="action_btn" i18n>Decline</span></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="note_section">
									<span class="tallyimg"><img src="assets/images/mobile-recieve/tally2.png"></span>
									<h5 i18n>Amount Due</h5>
									<h5 class="note" i18n>Notes</h5>
									<p class="note_dis" i18n>Thank you for your business. It's a pleasure to work with you. Thanks you for your business, team. We're excited and honored to be working with you.</p>
								</div>
							</div>
						</div>
					</div>	
				</div>
			</div>
			<div class="text_container text_container_box3" style="display: none;">
				<div class="text_container_inner">
					<div class="pages">
						<div class="page6">
								<div class="payment_details">
									<div class="payment_top">
										<div class="left">
											<div class="payment_text" i18n>Payment</div>
											<div class="payment_text" i18n>Item Details</div>
										</div>
										<div class="right" i18n>
											$4,000.00
										</div>
									</div>
									<div class="payment_bottom">
										<span class="Total" i18n>Total $4,000.00 USD</span>
									</div>
								</div>
								<div class="payment_method">
									<div class="payment_title" i18n>Payment Method</div>
									<ul>
										<li><img src="assets/images/mobile-recieve/payment_method1.svg"></li>
										<li><img src="assets/images/mobile-recieve/payment_method2.svg"></li>
									</ul>
								</div>
								<div class="payment_method_form">
									<div class="formfield w100">
										<input type="number" name="" placeholder="Debit/Credit Card" class="debitcard">
									</div>
									<div class="formfield w50 left">
										<input type="number" name="" placeholder="MM/YYYY" class="month">
									</div>
									<div class="formfield w50 right">
										<input type="password" name="" placeholder="CVV" class="cvv">
										<img src="assets/images/mobile-recieve/question-circle.svg">
									</div>
								</div>
								<div class="payment_button">
									<button i18n>PAY NOW</button>
								</div>
							</div>
							
								<!-- <div class="finish_button">
									<button i18n>Send & finish</button>
									<div class="highlight"></div>
									<div class="user_box_left_popup_inner popup Tally_popup7">
										<span class="popup_titel" i18n>Fast, easy and secure.</span>
										<span class="popup_dis popup_dis1" i18n>82% of agreements are completed in less than a day with DocuSign. </span>
										<span class="popup_dis">Click <span class="" i18n> Send & Finish </span> to move on. </span>				
										<div class="top_arrow"></div>
									</div>
								</div> -->
						
					</div>	
				</div>
			</div>	
			<div class="text_container text_container_box4" style="display: none;">
				<div class="text_container_inner">
					<div class="pages">
							<div class="page7">
								<div class="page7_inner">
									<div class="middle_width">
										<img src="assets/images/mobile-recieve/Document.png">
										<h3 i18n>Almost There</h3>
										<p i18n>Select Send & Finish to send your document and payment</p>
									</div>
								</div>	
								<div class="step_box">
									<div class="step_box_list active">
										<div class="circle"><img src="assets/images/mobile-recieve/check-mark.svg"></div>
										<div class="step_details">
											<h3 i18n>Document Signed</h3>
											<p i18n>Tally Master Services Agreement</p>
										</div>
									</div>
									<div class="step_box_list">
										<div class="circle"><img src="assets/images/mobile-recieve/check-mark.svg"></div>
										<div class="step_details">
											<h3 i18n>Payment Authorized</h3>
											<p i18n>$4000.00 USD</p>
										</div>
									</div>
									<div class="step_box_list">
										<div class="circle"><img src="assets/images/mobile-recieve/gray_circle.svg"></div>
										<div class="dashed_line"><img src="assets/images/mobile-recieve/dash_line.png"></div>
										<div class="step_details">
											<h3 i18n>Sent</h3>
											<p i18n>Not Completed</p>
										</div>
									</div>
								</div>
								<div class="finish_button">
									<button i18n [@buttonShakeEffect]="shakeState" (@buttonShakeEffect.done)="animationbuttonShakedone($event)" (click)="send_finish()">Send & finish</button>
									<div class="highlight" style="display: none;"></div>
									<div class="user_box_left_popup_inner popup Tally_popup7 trigger_click" [@tally7Effect]="tally7State" (@tally7Effect.done)="animationtally7done($event)" (click)="send_finish()">
										<span class="popup_titel" i18n>Fast, easy and secure.</span>
										<span class="popup_dis popup_dis1" i18n>82% of agreements are completed in less than a day with DocuSign. </span>
										<span class="popup_dis"><span i18n>Click</span> <span class="blue" i18n> Send & Finish </span><span i18n> to move on. </span></span>				
										<div class="top_arrow"></div>
									</div>
								</div>
							</div>
					</div>	
				</div>
			</div>			
			<div class="text_container text_container_box2" style="display:none">
				<div class="text_container_inner">
					<div class="requirment_details">
						<div class="requirment_details">
								<div class="top_row">
									<div class="left">
										<span class="red">*</span> <span class="req_text" i18n>Required</span>
									</div>
									<div class="right">
										<img src="assets/images/mobile-recieve/close-icon.svg">
									</div>
								</div>
						</div>
						<div class="field_form">
							<div class="left">
								<label><span i18n>Full Name</span><span class="red">*</span></label>
								<input type="" name="" placeholder="Mary Wilson">
							</div>
							<div class="right">
								<label><span i18n>Initials</span><span class="red">*</span></label>
								<input type="" name="" placeholder="MW">
							</div>
						</div>
						<div class="tab">
							<ul>
								<li i18n >Select style</li>
								<li  i18n class="active">DRAW</li>
							</ul>
						</div>
						<div class="signature_box">
							<div class="left" i18n>draw your signature</div>
							<div class="right" i18n>Clear</div>
							<div class="textarea">
								<img src="assets/images/mobile-recieve/signature.gif">
							</div>
						</div>
						<div class="discription_box">
							<p i18n>By selecting Adopt and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial.</p>
						</div>
					</div>
				</div>	
			</div>

			<div class="mobile_msg">
				<div class="user_box_left_popup_inner popup step2" [@step2Effect]="step2State">
					<span  class="popup_titel" i18n>Brand your emails.</span>
					<span class="popup_dis">	
						<span class="" i18n>Set up custom branding for a customized touch.</span>
					</span>
					<button class="blue_next_btn" (click)="show_documnet()">Next</button>
					<div class="top_arrow"></div>
				</div>
				<div class="user_box_left_popup_inner popup Tally_popup1" [@tally1Effect]="tally1State" (@tally1Effect.done)="animationtally1done($event)">
					<span  class="popup_titel" i18n>Documents are automatically formatted to your device with Responsive Signing.</span>
					<div class="top_arrow"></div>
				</div>
				<div class="user_box_left_popup_inner popup Tally_popup2"  [@tally2Effect]="tally2State"> 
					<span  class="popup_titel" i18n>Signers are guided through required fields.</span>
					<button (click)="scroll_document(target)" class="blue_next_btn">Next</button>
					<div class="top_arrow"></div>
				</div>
				<div class="user_box_left_popup_inner popup Tally_popup3 trigger_click" (click)="show_sign()"  [@tally3Effect]="tally3State">
					<span class="popup_titel"><span i18n>Just</span><span class="blue" i18n>click</span><span i18n>to sign. </span></span>
					<span class="popup_dis" i18n>
						It really is that easy.
					</span>
					<div class="top_arrow"></div>
				</div>
				<div class="user_box_left_popup_inner popup Tally_popup Tally_popup9" [@tally4Effect]="tally4State" (@tally4Effect.done)="animationtally4done($event)">
					<span  class="popup_titel" i18n>Each recipient creates their own signature.</span>
					<button (click)="show_after_Sign(target1)" class="blue_next_btn" i18n>Next</button>
					<div class="top_arrow"></div>
				</div>
				<div class="user_box_left_popup_inner popup Tally_popup5"  [@tally5Effect]="tally5State" (@tally5Effect.done)="animationtally5done($event)">
					<span  class="popup_titel" i18n>With guided signing, you’ll no longer receive incomplete documents.</span>
					<button (click)="show_approved()" class="blue_next_btn" i18n>Next</button>
					<div class="top_arrow"></div>
				</div>
				<div class="user_box_left_popup_inner popup Tally_popup6" [@tally6Effect]="tally6State" (@tally6Effect.done)="animationtally6done($event)">
					<span  class="popup_titel" i18n >Sign and pay in one seamless process.</span>
					<span class="popup_dis" i18n >Collect payment at signing with any major credit card, ACH, Apple Pay or Google Pay.</span>					
					<div class="top_arrow"></div>
				</div>
			</div>
		</div>
		<div class="overlay" (click)="add_Shake()" style="display: none;"></div>
	</div>
	<div class="popup_box" [@popupEffect]="popupState" (@popupEffect.done)="animationpopupdone($event)">
		<h3 class="margin0" i18n>Congratulations! </h3>
		<h3 class="" i18n>Your document has been signed.</h3>
		<p class="" i18n>Next, we’ll show you how easy it is to manage the documents you’ve sent for signature.</p>
		<div class="button_box">
			<a routerLink="/manage-sign"><button class="" i18n>Show me</button></a>
		</div>
	</div>
	<div class="fullpage_overlay" style="display: none;"></div>
</div>
