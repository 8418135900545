<!-- <link rel="stylesheet" href="last_page.css">
<link rel="stylesheet" href="styles.css"> -->
<div class="containter">
	<div class="row">
		<div class="top_header">
			<div class="left">
				<span class="new" i18n>new</span>
				<span class="covid" i18n>DocuSign and COVID-19</span>
			</div>
			<div class="right">
				<ul>
					<li i18n>Sales 1-877-720-2040</li>
					<li i18n>Support</li>
					<li i18n>Access Documents</li>
					<li i18n>Log In </li>
				</ul>
			</div>
		</div>
		<div class="menu">
			<div class="logo"><img src="assets/images/end-page/logo.png"></div>
			<div class="menu_item">
				<ul>
					<li i18n>Product</li>
					<li i18n>Solutions</li>
					<li i18n>Developers</li>
					<li i18n>Pricing</li>
				</ul>
			</div>
			<div class="free_trial_box">
				<div class="cotnact" i18n>Contact Sales</div>
				<div class="Free_trial" i18n>Free Trial</div>
			</div>
		</div>
		<div class="banner">
			<div class="banner_inner">
				<h1 i18n> Agreement has</h1>
				<h1 i18n>never been easier.</h1>
				<div class="trial_dis" i18n>Start a free 30-day DocuSign eSignature trial today.</div>
				<button i18n>START YOUR FREE TRIAL</button>
				<div class="contact_us" i18n>Or Contact Sales: 1-877-720-2040 </div>
			</div>
		</div>
		<div class="footer_container">
			<div class="footer">
				<div class="footer_inner">
					<div class=" footer_list footer_list1">
						<h3>PRODUCTS</h3>
						<ul class="ul1">
							<li>eSignature</li>
							<li>Contract Lifecycle Management</li>
							<li>Document Generation</li>
							<li>Agreement Analytics</li>
							<li>See All Products</li>
						</ul>
						<h3>PRICING</h3>
						<ul>
							<li>eSignature Plans</li>
							<li>Real Estate Plans</li>
							<li>API Plans</li>
						</ul>
					</div>
					<div class=" footer_list footer_list2">
						<h3>PARTNERS</h3>
						<ul class="ul1">
							<li>Partners Overview</li>
							<li>Partners Login</li>
						</ul>
						<h3>DEVELOPERS</h3>
						<ul>
							<li>Developer Center</li>
							<li>APIs & SDKs</li>
						</ul>
					</div>
					<div class=" footer_list footer_list3">
						<h3>SUPPORT</h3>
						<ul class="ul1">
							<li>Customer Success</li>
							<li>Support Center</li>
							<li>Knowledge Market</li>
							<li>DocuSign University</li>
							<li>Community</li>
						</ul>
					</div>
					<div class=" footer_list footer_list4">
						<h3>RESOURCES</h3>
						<ul>
							<li>Why DocuSign</li>
							<li>Resource Center</li>
							<li>Blog</li>
							<li>Events</li>
							<li>Webinars</li>
							<li>Customer Stories</li>
							<li>Legality Guide</li>
							<li>Trust Center</li>
						</ul>
					</div>
					<div class=" footer_list footer_list5">
						<h3>COMPANY</h3>
						<ul>
							<li>About Us</li>
							<li>Leadership</li>
							<li>Careers</li>
							<li>Investor Relations</li>
							<li>News Center</li>
							<li>DocuSign For Forests</li>
							<li>DocuSign Impact</li>
							<li>DocuSign Momentum</li>
							<li>Contact Us</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="footer2">
				<div class="footer2_inner">
					<div class="left">
						<div class="left_heading" i18n>TRENDING TOPICS</div>
					</div>
					<div class="right">
						<ul>
							<li i18n>Streamline your contract lifecycle</li>
							<li i18n>Accelerate your time to revenue</li>
							<li i18n>DocuSign solutions for departments and industries</li>
							<li i18n>Free digital signature software</li>
							<li i18n>eSign solutions real estate group</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="footer3">
				<div class="footer3_inner">
					<div class="left">
						<ul>
							<li><img src="assets/images/end-page/fb.svg"></li>
							<li><img src="assets/images/end-page/twitter.svg"></li>
							<li><img src="assets/images/end-page/youtube.svg"></li>
							<li><img src="assets/images/end-page/linkdin.svg"></li>
						</ul>
					</div>
					<div class="right">
						<ul>
							<li><img src="assets/images/end-page/android.png"></li>
							<li><img src="assets/images/end-page/windows.png"></li>
							<li><img src="assets/images/end-page/mac.png"></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="footer4">
				<div class="footer4_inner">
					<div class="left">
						<div class="country_box">
							<div class="button"><img class="global" src="assets/images/end-page/global.svg"><span i18n>United States</span><img class="down" src="assets/images/end-page/down.svg"></div>
						</div>
						<ul>
							<li i18n>Terms of Use </li>
							<li i18n>Privacy Policy</li>
							<li i18n>Cookie Settings</li>
							<li i18n>Intellectual Property</li>
						</ul>
					</div>
					<div class="right">
						<span i18n>© DocuSign Inc. 2020</span>
					</div>
				</div>	
			</div>
		</div>
	</div>
</div>
