import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { trigger,  state,  style,  animate,  transition, query, stagger, keyframes } from '@angular/animations';
import $ from "jquery";
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-select-recipent',
  templateUrl: './select-recipent.component.html',
  styleUrls: ['./select-recipent.component.css'],
  animations: [
    trigger('step2Effect', [
      state('initial', style({
        transform: 'translateY(70px)',
        display:'none',
        opacity: '0'
      })),
      state('final', style({
      })),
      state('done', style({
        display:'none',
      })),
      transition('initial=>final',[
      	style({
      		display:'block'
      	}),
      	animate('600ms ease-in-out')
      ])
    ]),
    trigger('slideEffect', [
      state('initial', style({
        transform: 'scaleY(0)',
        height:'0px',
        opacity: '0',
        display:'none'
      })),
      state('final', style({
        transform: 'scaleY(1)',
        display : 'block'

      })),
      state('swap', style({
      	transform: 'translateY(-110%)',
      })),
      transition('initial=>final',[
      	style({
      		display:'block',
          top: '-43px'
      	}),
      	animate('400ms ease-in-out')
      ]),
      transition('final=>swap',[
      	style({
      		display:'block'
      	}),
      	animate('400ms ease-in-out')
      ])
    ]),
    trigger('lineEffect', [
      state('entry', style({
        transform: 'scaleY(0)',
        height:'0px',
        opacity: '0',
        display:'none'
      })),
      state('final', style({
        transform: 'scaleY(1)',
        display : 'block'

      })),
      transition('entry=>final',[
        style({
          display:'block',
        }),
        animate('9s ease-in-out')
      ]),
    ]),
    trigger('highlightEffect', [
      state('final', style({
        height:'195px'

      })),
      transition('initial=>final', animate('600ms ease-in-out')),
    ]),
    trigger('step3Effect', [
      state('initial', style({
        transform: 'translate(100px,-18px)',
        display:'none',
        opacity: '0'
      })),
      state('final', style({
        transform: 'translate(0px, -18px)',
        display:'block'
      })),
      state('done', style({
        transform: 'translate(0px, 40px)',
        display:'block'
      })),
      state('hide', style({
        display:'none'
      })),
      transition('initial=>final',[
      	style({
      		display:'block'
      	}),
      	animate('400ms ease-in-out')
      ]),
      transition('final=>done',[
      	style({
      		display:'block'
      	}),
      	animate('600ms ease-in-out')
      ])
    ]),
    trigger('step4Effect', [
      state('initial', style({
        display:'none',
        opacity: '0'
      })),
      state('done', style({
      display:'none',
      })),
      transition('initial=>final',[
      	style({
      		display:'block'
      	}),
      	animate('0.5s ease-in-out')
      ])
    ]),
    trigger('swapEffect', [
      state('initial', style({
      })),
      state('swap', style({
      	transform :'translateY(110%)'
      })),
      transition('initial=>swap',
      	animate('400ms ease-in-out')
      )
    ]),
    trigger('popupEffect', [
      state('initial', style({
      transform: 'translate(-50%,70px)',
        display:'none',
        opacity: '0'
      })),
      transition('initial=>final',[
        style({
          display:'block'
        }),
        animate('400ms ease-in-out')
      ])
    ]),
  ]
})
export class SelectRecipentComponent implements OnInit {
	  step2State = 'initial';
  	slideState = 'initial';
  	step3State = 'initial';
  	step4state = 'initial';
  	swapState = 'initial';
    lineState = 'initial';
    highlightState = 'initial';
    popupState = 'initial';
    mySubscription;

  	constructor(public app: AppComponent){}

  	ngOnInit(): void {
  	}
  	
  	ngAfterViewInit() {
     	this.app.timerId.push(
      	setTimeout(()=>{
          $('.overlay').css('background','rgba(0, 0, 0, .4)');
     			$('.overlay').fadeIn();
          $('.user_box.odd .user_box_left_inner').css('border-top','1px solid #D9D9D9');
          $('.user_box.odd .user_box_left_inner').css('border-bottom','1px solid #D9D9D9');
     			$('.user_box.odd .user_box_left_inner').addClass('higlight');
        	this.step2State = 'final';
     		},2000)
      );
  	}
  	show_prefilled(){
  		$('.overlay').fadeOut();
      $('.user_box.odd .user_box_left_inner').removeAttr('style');
   		$('.user_box.odd .user_box_left_inner').removeClass('higlight');
   		$('.user_box.odd .user_box_left').css('z-index', 0);
   		this.step2State = 'done';
   		$('.user_box.odd').find('input[type="text"][name="name"]').attr('placeholder','Blake Hayes');
   		$('.user_box.odd').find('input[type="text"][name="email"]').attr('placeholder','blake.hayes@mail.com');
      this.app.timerId.push(
     		setTimeout(()=>{
            this.lineState = 'entry';
            $('.left_img2').show();
        		this.slideState = 'final';
            $('.discription ').addClass('slide1_down');
            this.lineState = 'final';
            $('.button_box').addClass('box_margin_top');
     		},1500)  	
      );
    }

  	animationslideEffect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
        this.app.timerId.push(
  	  		setTimeout(()=>{
  	      	$('.overlay').fadeIn();
            $('.need_text .highlight').fadeIn();
  	  			$('.user_box.odd .need_text').css('z-index', 3);
  	  			this.step3State = 'final';
  	   		},1500)
        );
  		}
  		if($event.fromState == 'final' && $event.toState == 'swap'){
        this.app.timerId.push(
  	  		setTimeout(()=>{
            $('.overlay').removeAttr('style');
            $('.drag_img').hide();
  	      	$('.overlay').fadeIn();
  	  			this.popupState = 'final';
  	   		},1500)
        );
  		}
  	}
    animationpopupdone($event){
      if($event.fromState == 'initial' && $event.toState == 'final'){
        this.app.showRefreshBtn = true;
      }
    }

  	animationstep3Effect($event){
  		if($event.fromState == 'initial' && $event.toState == 'final'){
        this.app.timerId.push(
    			setTimeout(()=>{
    				  $('.need_text .drop_down').fadeIn('600');
              this.highlightState = 'final';
              this.step3State = 'done';
  	   		},2000)
        );
  		}
  		if($event.fromState == 'final' && $event.toState == 'done'){
        this.app.timerId.push(
          setTimeout(()=>{
    		    $('.drop_down ul li').first().css('background', '#F4F4F4').fadeIn('600');
          },1500)
        );
        this.app.timerId.push(
    			setTimeout(()=>{
    				$('.overlay').fadeOut();
    				$('.need_text .drop_down').fadeOut();
    				$('.user_box.odd .need_text').removeAttr('style');
            $('.need_text .highlight').fadeOut();
            $('.need_text .need_text_inner').css('z-index',2);
    				this.step3State = 'hide';
  	   		},3600)
        );
  		}
  		if($event.fromState == 'done' && $event.toState == 'hide'){
        this.app.timerId.push(
    			setTimeout(()=>{
    				$('.overlay').fadeIn();
            setTimeout(()=>{
              $('.recipent_box2_text2').css('z-index', 3);
              $('.recipent_box2 .highlight.sign').fadeIn();
    				  this.step4state = 'final';
              },400)
  	   		},2000)
        );
  		}
  	}

  	show_checked(){
  		$('.overlay').fadeOut();
      $('.recipent_box2 .highlight.sign').fadeOut();
  		$('.recipent_box2_text2').removeAttr('style');
  		$('img.checkbox').hide();
  		$('img.checkbox_checked').show();
      $('.discription ').removeClass('slide1_down');
      $('.discription ').addClass('slide_down');
  		$('.left_img_no1').show();
  		$('.left_img_no2').show();
  		$('.left_img2').hide();
  		$('.drag_img').show();
  		this.step4state = 'done';
      this.app.timerId.push(
    		setTimeout(()=>{
    			this.swapState = 'swap';
    			this.slideState = 'swap';
    		},1000)
      );
  	}

}
