<div class="header_white">
	<div class="header_white_inner">
		<div class="left">
			<div class="logo"><img src="assets/images/manage-sign/logo.svg"></div>
		</div>
		<div  class="middle">
			<ul>
				<li class=""><span i18n>Home</span></li>
				<li class="active"><span i18n >Manage</span></li>
				<li class=""><span i18n>Templates</span></li>
				<li class=""><span i18n>Reports</span></li>
				<li class=""><span i18n>Admin</span></li>
			</ul>
		</div>
		<div class="right">
			<ul>
				<li class="img1"><img src="assets/images/manage-sign/help.svg"></li>
				<li class="img2"><img src="assets/images/manage-sign/Medium.png"></li>
			</ul>
		</div>
	</div>	
</div>

<div class="middle_container">
	<div class="left">
		<div class="left_inner">
			<div class="start_now" i18n>START NOW</div>
			<div class="list list1">
				<h3 i18n>Envelopes</h3>
				<ul>
					<li><img src="assets/images/manage-sign/inbox.svg"><span i18n>Inbox</span></li>
					<li class="active"><img src="assets/images/manage-sign/sent.svg"><span i18n>Sent</span></li>
					<li><img src="assets/images/manage-sign/draft.svg"><span i18n>Drafts</span></li>
					<li><img src="assets/images/manage-sign/trash.svg"><span i18n>Deleted</span></li>
					<li><img src="assets/images/manage-sign/power_form.svg"><span i18n>PowerForms</span></li>
				</ul>
			</div>
			<div class="list list2">
				<h3 i18n>Quick views</h3>
				<ul>
					<li><img src="assets/images/manage-sign/action_required.svg"><span i18n>Action Required</span></li>
					<li><img src="assets/images/manage-sign/time.svg"><span i18n>Waiting for Others</span></li>
					<li><img src="assets/images/manage-sign/alert-triangle.svg"><span i18n>Expiring Soon</span></li>
					<li><img src="assets/images/manage-sign/completed.svg"><span i18n>Completed</span></li>
					<li><img src="assets/images/manage-sign/alert-triangle.svg"><span i18n>Authentication Failed</span></li>
				</ul>
			</div>
			<div class="list3">
				<h3><span i18n>Folders </span><img src="assets/images/manage-sign/plus.svg"></h3>
			</div> 
		</div>	
	</div>
	<div class="right">
		<div class="right_inner">
			<div class="sent_search">
				<div class="left">
					<h3 i18n>Sent</h3>
				</div>
				<div class="right">
					<div class="search">
						<input type="" name="" placeholder="Search Sent and Folders">
						<img src="assets/images/manage-sign/search.svg">	
					</div>
					<div class="filter">
						<span><img src="assets/images/manage-sign/filter.svg"><span i18n>FILTERS</span></span>
					</div>
				</div>
			</div>
			<div class="manage_list heading">
				<div class="box1"></div>
				<div class="box2" i18n>Subject</div>
				<div class="box3" i18n>Status</div>
				<div class="box4" i18n>Sent</div>
				<div class="box5"></div>
			</div>
			<!-- <div class="divided_line"></div> -->
			<div class="manage_list manage_list1">
				<div class="cursor" style="display: none;"><img [@cursorEffect]="cursorstate" src="assets/images/manage-sign/cursor.png"></div>
				<div class="box1">
					<img src="assets/images/manage-sign/checkbox.svg" class="checkbox">	
					<img src="assets/images/manage-sign/time.svg" class="time">
					<img src="assets/images/manage-sign/green_tick.svg" class="tick" style="display: none;">
				</div>
				<div class="box2">
					<div class="manage_title" i18n>Sign the Tally Master Services Agreement.</div>
					<div class="manage_discription" i18n>To: Mary Wilson, Blake Hayes</div>
				</div>
				<div class="box3">
					<div class="status waiting" [@waitingEffect]="waitingstate" style="position: absolute;" i18n>Waiting for Others</div>
					<div class="status completed" [@completeEffect]="completestate" style="position: absolute;" i18n>Completed</div>
				</div>
				<div class="box4">
					<div class="date" i18n>4/30/2020</div>
					<div class="time" i18n>10:30:26 am</div>
				</div>
				<div class="box5">
					<div class="send_btn"><span i18n>RESEND </span><img src="assets/images/manage-sign/select_dropdown.svg" class="select"></div>
				</div>
			<div class="highlight" style="display: none;"></div>
			<div class="user_box_left_popup_inner popup" [@step1Effect]="step1state" (@step1Effect.done)="animationstep1Effect($event)">
				<span class="popup_titel" i18n>Track all your</span>
				<span class="popup_titel" i18n>agreements in one place.</span>
				<span class="popup_dis"><span  class="" i18n>Manage multiple envelopes at once and see automatic updates in your dashboard. </span></span>
				<button (click)="show_cursor()" class="popup_btn " i18n>next</button>
				<div class="top_arrow "></div>
			</div>
			<div class="user_box_left_popup_inner popup popup2" [@step2Effect]="step2state" (@step2Effect.done)="animationstep2Effect($event)">
				<span class="popup_titel" i18n>View completed </span>
				<span class="popup_titel" i18n>agreements.</span>
				<span class="popup_dis"><span  class="" i18n>Access completed agreements and a full audit trail with a single click. </span></span>
				<button class="popup_btn" (click)="step2next()" i18n>next</button>
				<div class="top_arrow "></div>
			</div>
			</div>
			<!-- <div class="divided_line"></div> -->
			<div class="manage_list manage_list2">
				<div class="box1">
					<img src="assets/images/manage-sign/checkbox.svg" class="checkbox">	
					<img src="assets/images/manage-sign/time.svg" class="time">
				</div>
				<div class="box2">
					<div class="manage_title" i18n>Business Sales Invoice.pdf</div>
					<div class="manage_discription" i18n>To: Blake Hayer</div>
				</div>
				<div class="box3">
					<div class="status" i18n>Waiting for Others</div>
				</div>
				<div class="box4">
					<div class="date" i18n>4/30/2020</div>
					<div class="time" i18n>08:24:12 am</div>
				</div>
				<div class="box5">
					<div class="send_btn"><span i18n>RESEND</span> <img src="assets/images/manage-sign/select_dropdown.svg" class="select"></div>
				</div>
			</div>
			<!-- <div class="divided_line"></div> -->
			<div class="manage_list manage_list3">
				<div class="box1">
					<img src="assets/images/manage-sign/checkbox.svg" class="checkbox">	
					<img src="assets/images/manage-sign/green_tick.svg" class="tick">
				</div>
				<div class="box2">
					<div class="manage_title" i18n>Production Agreement for TM.docx</div>
					<div class="manage_discription" i18n>To: William Peterson, Jessica McMilan</div>
				</div>
				<div class="box3">
					<div class="status completed" i18n>Completed</div>
				</div>
				<div class="box4">
					<div class="date" i18n>4/22/2020</div>
					<div class="time" i18n>08:23:12 am</div>
				</div>
				<div class="box5">
					<div class="send_btn"><span i18n>move </span><img src="assets/images/manage-sign/select_dropdown.svg" class="select"></div>
				</div>
			</div>
			<!-- <div class="divided_line"></div> -->
			<div class="manage_list manage_list4">
				<div class="box1">
					<img src="assets/images/manage-sign/checkbox.svg" class="checkbox">	
					<img src="assets/images/manage-sign/green_tick.svg" class="tick">
				</div>
				<div class="box2">
					<div class="manage_title" i18n>Sales-Report-2020.pdf</div>
					<div class="manage_discription" i18n>To: Bo Dallas, Dimitri Vegas</div>
				</div>
				<div class="box3">
					<div class="status completed" i18n>Completed</div>
				</div>
				<div class="box4">
					<div class="date" i18n>4/21/2020</div>
					<div class="time" i18n>11:32:23 am</div>
				</div>
				<div class="box5">
					<div class="send_btn"><span i18n>move </span><img src="assets/images/manage-sign/select_dropdown.svg" class="select"></div>
				</div>
			</div>
			<!-- <div class="divided_line"></div> -->
		</div>	
	</div>	
</div>
<div class="overlay" style="display: none;"></div>
<app-footer></app-footer>