<app-header></app-header>
<div class="container_box">
	<div class="container_box_inner">
		<h3 i18n *ngIf="showContent">Add documents</h3>
		<div class="upload_box">
			<div class="upload_box_inner" style="display: none;">
				<div class="upload_image">
					<span class="upload_image_inner blue_img"><img src="assets/images/upload.png"></span>
					<span class="upload_image_inner gray_img" style="display: none;"><img src="assets/images/upload.png"></span>
					<span class="upload_image_text" i18n>Drop your files here, or</span>
						<div class="upload_height"><span class="browse_btn"><span i18n>Browse</span> <img src="assets/images/menu.png"></span>
						<div class="dropDown"style="display: none;">
							<ul>
								<li><span><img src="assets/images/box.png"><span class="drop_text" i18n>Box</span></span></li>
								<li class="dropbox"><span><img src="assets/images/drop_box.png"><span class="drop_text" i18n>Dropbox</span></span></li>
								<li><span><img src="assets/images/GoogleDrive.png"><span class="drop_text" i18n>Google Drive</span></span></li>
								<li class="line"></li>
								<li class="last-text" i18n>Use templates</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="upload_right_box" [@correctEffect]="currentState">
					<div class="upload_right_box_inner">
						<div class="right_arrow"></div>
						<span class="upload_text1" i18n>Step 1</span>
						<span class="upload_text2" i18n>Select your document.</span>
						<span class="upload_text3" i18n>Add multiple documents to a single envelope.</span>
						<a class="blue_next_btn" routerLink="/select-recipent" i18n>Next</a>
					</div>
				</div>

			</div>
			<div class="popup_overlay"></div>
			<div  *ngIf="showContent" class="popup_box">
				<h3>Welcome to the DocuSign eSignature tour.</h3>
				<p>We'll show you how easy it is to send, sign, and receive digital documents.</p>
				<div class="show_me" (click)="showme()"><button>show me</button></div>
			</div>
			<div class="popup_mobile popup_box" *ngIf="isMobile && isShown">
				<h3 i18n>This demo is best viewed on desktop.</h3>
				<p i18n>Click the button below to email a link to view later.</p>
				<div  class="show_me ng-tns-c21-0">
					<a [href]="emailstring" ngxClipboard [cbContent]="linktocopy" i18n>EMAIL LINK</a>
					<button class="close_popup" (click)="hide_mobilePopup()" i18n>Dismiss</button>
				</div>
			</div>
		</div>
		<div class="button_box">
			<span class="next_btn">
			<button>Next</button></span>
		</div>
	</div>
</div>
<app-footer></app-footer>