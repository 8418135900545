

<div [@page1Effect]="page1state" (@page1Effect.done)="animationpage1Effect($event)" class="page page1">
	<h1 class="tally_blue_RGB" i18n><img src="assets/images/sign-doc/tally_blue_RGB.png" ></h1>
	<h3 i18n>MASTER SERVICES AGREEMENT</h3>
	<p><span i18n>This Services Agreement (the “</span><strong>Agreement</strong><span i18n>”) sets forth terms under which company (“</span><strong>Company</strong><span i18n>”) shall provide services to client, a (the “</span><strong>Client</strong><span i18n>”). This Agreement is effective as of August 30, 2018 (“</span><strong>Effective Date</strong><span i18n>”). </span></p>
	<p><span i18n>1. </span><strong>Services</strong><span i18n>. Company shall provide services to the Client as described on one or more Statements of Work signed by Company and Client that reference this Agreement (“</span><strong>SOW</strong><span i18n>” or “</span><strong>Statement of Work</strong><span i18n>”).Company shall perform Services in a prompt manner and have the final service (“</span><strong>Deliverable</strong><span i18n>”) ready for Client no later than the due date specified in the applicable SOW (“</span><strong>Completion Date</strong><span i18n>”). This due date is subject to change in accordance with the Change Order process defined in the applicable SOW. Client shall assist Company by promptly providing all information requests known or available and relevant to the Services in a timely manner. </span></p>
	<p><span i18n>2. </span><strong>Contract Price</strong><span i18n>. For performance of the Services and rendering the Deliverable, Client shall pay to Company all fees due under the applicable SOW. </span></p>
	<p><span i18n>3. </span><strong>Deposit</strong><span i18n>. An initial payment (the “</span><strong>Deposit</strong><span i18n>”) of is due to Company at signing. 50% of the Deposit shall be non-refundable in the event that Client cancels the project prior to its completion. </span></p>
	<p><span i18n>4. </span><strong>Dates of Performance</strong><span i18n>. Company will begin performing services upon receipt of signed Agreement and Deposit. Unless terminated as provided in this Agreement, Company will complete Services by the Completion Date. Deliverable shall be furnished to Client within 72 hours of final payment for the Services. </span></p>
	<p><span i18n>5. </span><strong>Change in Services</strong><span i18n>. If Client desires changes to the SOW, Client shall submit to Company a written request in accordance with the change order process defined in the applicable SOW. The parties may execute additional Statements of Work describing Services, which will become part of this Agreement upon execution by Company and the Client. If additional SOW is executed, then Client shall pay Company for all services performed prior to the additional SOW before Company begins work on the new SOW. </span></p>
	<p><span i18n>6. </span><strong>Termination</strong><span i18n>. Company shall have the right to modify, reject, or terminate any SOW and any related work in process with five days written notice to Client. In the event Company terminates the SOW prior to completion of Services, the Client shall pay Company the fees due under the SOW with respect to Services completed as of the date of termination. Payment for completed work will be deducted from the deposit. Company will retain the non- refundable 50% of the Deposit and return any unearned portion exceeding 50% of the Deposit. Any amount due for services performed by Company above the deposit will be billed to Client and Client shall promptly pay. Upon settlement of funds due to Company, all Client provided materials will be returned to Client and all Client use rights in the work in process as described in Section 9 will be transferred to Client.</span> </p>
	<p i18n>Accepted and agreed to as of the Effective Date by the authorized representative of each party: </p>				
</div>
<div [@page1Effect]="page1state" class="page page2">
	<p class="page_margin" i18n>Accepted and agreed to as of the Effective Date by the authorized representative of each part:</p>
	<table class="table_box">
		<tr>
			<th i18n>CLIENT</th>
			<th i18n>COMPANY</th>
		</tr>
		<tr>
			<td class="signin"><label i18n>Signature </label>
				<span class="sign_img sign_img1">
					<img src="assets/images/review-doc/signature.jpg"> 								
				</span>
			</td>
			<td i18n>Signature</td>
		</tr>	
		<tr>
			<td><label i18n>Print Name</label> <span class="input_field"i18n >Mary Wilson</span></td>
			<td i18n>Print Name</td>
		</tr>	
		<tr>
			<td><label i18n>Print Title </label><span class="input_field" i18n>CEO</span></td>
			<td i18n>Print Title</td>
		</tr>
		<tr>
			<td><label i18n>Date Signed</label> <span class="input_field" i18n>05/11/2020 </span></td>
			<td i18n>Date Signed</td>
		</tr>	
		<tr>
			<td><label i18n>Notice Address</label> <span class="input_field" i18n>5022 Gate Parkway Suite</span></td>
			<td i18n>Notice Address</td>
		</tr>	
		<tr>
			<td><label i18n>Email</label> <span class="input_field" i18n>mary.wilson@mail.com </span></td>
			<td i18n>Email</td>
		</tr>	
		<tr>
			<td><label i18n>Primary Contact</label> </td>
			<td i18n>Primary Contact</td>
		</tr>	
		<tr>
			<td class="blue_signin"><label i18n>Legal Approval</label> 
			</td>
			<td i18n>Legal Approval</td>
		</tr>	
	</table>
</div>
<div [@page1Effect]="page1state" class="page page3">
	<div class="Invoice_box">
		<div class="Invoice_box_inner">
			<div class="address2">
				<div class="tally_title tally_blue_RGB" i18n><img src="assets/images/sign-doc/tally_blue_RGB.png" ></div>
				<div class="address">
					<div class="left" i18n>From</div>
					<div class="right">
						<span i18n>Tally</span>
						<span i18n>	123 Main St.</span>
						<span i18n>Suite 1800</span>
						<span i18n>Seattle, WA 98122</span>
					</div>
				</div>
			</div>
			<div class="address1">
				<div class="left_box address">
					<div class="left" i18n>Invoice For</div>
					<div class="right">
						<span i18n>Acme Realty Co.</span>
						<span i18n>321 First Street</span>
						<span i18n>Seattle, WA 98122</span>
					</div>
				</div>
				<div class="right_box">
					<div class="left">
						<span i18n>Invoice ID</span>
						<span i18n>PO Number</span>
						<span i18n>Issue Date</span>
						<span i18n>Due Date</span>
					</div>
					<div class="right">
						<span i18n>00006</span>
						<span i18n>123456</span>
						<span i18n>04/21/20</span>
						<span i18n>05/21/20</span>
					</div>
				</div>
			</div>
			<div class="address1 address3">
				<div class="left_box address">
					<div class="left" i18n>Subject</div>
					<div class="right">
						<span i18n>Staging Services</span>
					</div>
				</div>
				<div class="right_box">
				</div>
			</div>
		</div>	
	</div>
	<div class="page3_inner">
		<div class="page3_heading">
			<div class="left" i18n>Description</div>
			<div class="right" i18n>Amount</div>
		</div>
		<div class="page3_detail">
			<div class="left" i18n>Staging fee for interior and exterior of 301 Central Avenue home.</div>
			<div class="right">
				<ul>
					<li i18n>$4,000</li>
					<li class="amount">
						<div class="amount_inner">
							<span class="amount_btn" i18n> $4000.00 USD</span> 
						</div>
					</li>
					<li><span class="action_btn" i18n>Approve</span></li>
				</ul>
			</div>
		</div>
		<div class="note_section">
			<h5 i18n>Amount Due</h5>
			<p class="note" i18n>Notes</p>
			<p class="note_dis" i18n>Thank you for your business. It's a pleasure to work with you.</p>
		</div>
	</div>
</div>
<div [@page1Effect]="page1state" class="page page4" #targetscroll>
	<div class="logo"><img src="assets/images/review-doc/logo.png"></div>
	<div class="heading_box"><span>	Certificate Of Completion</span></div>
	<div class="certificate_box">
		<div class="c-left">
			<ul>
				<li i18n>Envelope Id: 971221887443</li>
				<li i18n>Subject: Tally Master Service Agreement</li>
				<li i18n>Source Envelope:</li>
				<li i18n>Document Pages:3</li>
				<li><span i18n>Certificate Pages:1</span><span class="initial" i18n>Initials:1</span></li>
			</ul>
		</div>
		<div class="c-right">
			<ul class="c-right_ul1">
				<li i18n>Status:Completed</li>
			</ul>
			<ul class="c-right_ul2">
				<li i18n>Envelope Originator:</li>
				<li i18n>Larry Lee</li>
			</ul>
		</div>
	</div>
	<div class="certificate_box_overlay">
		<div class="user_box_left_popup_inner popup" [@popupEffect]="popupstate" (@popupEffect.done)="animationpopupdone($event)">
			<span class="popup_titel " i18n>A full audit trail is included with every completed document.</span>
			<a routerLink="/finish"><button class="popup_btn" i18n>next</button></a>
			<div class="top_arrow "></div>
		</div>
		<div class="highlight" style="display: none;"></div>
		<div class="certificate_box_overlay_inner">
			<div class="c-left">
				<ul>
					<li i18n>AutoNav: Enabled</li>
					<li i18n>Envelopeld Stamping:Enabled</li>
					<li i18n>Time Zone:(UTC-08:00)Pacific Time (US8 Canada!</li>
				</ul>
			</div>
			<div class="c-right">
				<ul class="c-right_ul2">
					<li i18n>23 Main St., Suite 1800</li>
					<li i18n>Seattle, WA 98122</li>
					<li i18n>larry.lee@mail.com</li>
					<li i18n>IP Address: 981.111.201.123</li>
				</ul>
			</div>
		</div>
		<div class="record_tracking">
			<div class="heading_box"><span i18n>	Certificate Of Completion</span></div>
			<div class="record_tracking_inner">
				<div class="record record1">
					<div class="record_list record_list1" >
						<label i18n>Status:</label><span class="record_detail" i18n>Original</span>
					</div>
					<div class="record_list record_list2" >
						<label></label><span class="record_detail" i18n>4/21/2020 1:50:25 PM</span>
					</div>
				</div>
				<div class="record record2">
					<div class="record_list record_list1">
						<label i18n>Holder:</label><span class="record_detail" i18n>Larry Lee</span>
					</div>
					<div class="record_list record_list2">
						<label></label><span class="record_detail" i18n>larry.lee@mail.com</span>
					</div>
				</div>
				<div class="record record2">
					<div class="record_list record_list1">
						<label i18n>Location:</label><span class="record_detail" i18n>DocuSign</span>
					</div>
				</div>
			</div>
		</div>
		<div class="record_event">
			<div class="heading_box heading_box3">
				<span class="heading1" i18n>Signer Events</span>
				<span class="heading2" i18n>Signature</span>
				<span class="heading3" i18n>Timestamp</span>
			</div>
			<div class="record_event_inner">
				<div class="event event1">
					<div class="event_inner event1">
						<div class="event1_1" i18n>Mary Wilson</div>
						<div class="event1_2" i18n>mary.wilson@mail.com</div>
						<div class="event1_3" i18n>Security Level:Email, Account Authenlication(None)</div>
					</div>
				</div>
				<div class="event event2">
					<div class="event2_1"><img src="assets/images/review-doc/signature1.png"></div>
					<div class="event2_2" i18n>Signature Adoption:Pre-selected Style</div>
					<div class="event2_3" i18n>Using IP Address: 44.172.33.201</div>
				</div>
				<div class="event event3">
					<div class="event3_1" i18n>Sent: 4/21/2020 1:54:03 PM</div>
					<div class="event3_2" i18n>Resent: 4/27/2020 1:50:02 PM</div>
					<div class="event3_3" i18n>Viewed:4/27/2020 9:19:40 PM</div>
					<div class="event3_4" i18n>Signed: 4/27/2020 9:20:30 PM</div>
				</div>
			</div>
		</div>
		<div class="electronic">
			<div class="electronic_inner1" i18n>Electronic Record and Signature Disclosure:</div>
			<div class="electronic_inner2" i18n>Accepted:4/27/2020 9:19:40 PM</div>
			<div class="electronic_inner3" i18n>ID: 99b954f9-a0c7-4116-bf75-3584d2982247</div>
		</div>
	</div>
	<div class="overlay" style="display: none;"></div>
</div>
