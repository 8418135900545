<footer  [ngClass]="{'mangefooter' : manage, 'footer': !manage}">
<ul *ngIf="!manage">
	<li><img src="assets/images/globe.png"></li>
	<li i18n>English</li>
	<li i18n>Powered by DocuSign</li>
	<li i18n>Contact Us</li>
	<li i18n>Terms of Use</li>
	<li i18n>Privacy</li>
	<li i18n>Intellectual Property </li>
	<li i18n>Copyright © 2017 DocuSign, Inc All rights reserved.</li>
</ul>

<ul *ngIf="manage">
	<li><img src="assets/images/globe.png"></li>
	<li><span i18n>English</span><img src="assets/images/english_select.svg" class="english_select"></li>
	<li i18n>Powered by DocuSign</li>
	<li i18n>Contact Us</li>
	<li i18n>Terms of Use</li>
	<li i18n>Privacy</li>
	<li i18n>Intellectual Property </li>
	<li i18n>xDTM Complaint </li>
	<li i18n>Copyright © 2017 DocuSign, Inc All rights reserved.</li>
</ul>
</footer>
